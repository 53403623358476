import { InputLabel } from 'common/form/label.tsx';
import { CurrencyInput } from 'common/components/currencyInput.tsx';
import { Button } from 'common/components/button.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { buyCoins, Coin, CoinStatus } from 'coin/coin.store.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Address } from '@ton/core';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { z } from 'zod';
import { createValidator } from 'common/form/validate.ts';
import { useTranslation } from 'i18n';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonBalance, useUser } from 'user/user.store.ts';
import { bigPumpSdk, stonfiV2Sdk } from 'bigPumpSdk/sdk.store.ts';
import classNames from 'classnames';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useOperationDrawer } from 'coin/components/operationInProcess.tsx';
import { MIN_BUY_AMOUNT } from 'common/constants';
import debounce from 'lodash-es/debounce';
import { SlippageSettings } from 'coin/slippage/slippageSettings.tsx';
import { useSlippage } from 'coin/slippage/slippage.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useKeyboardOpen } from 'common/hooks/useKeyboardOpen.ts';

const debounceTime = 200;

type BuyTokenWidgetProps = {
    coin: Coin;
    defaultAmount?: string;
    floatingButton?: boolean;
};

const validationSchema = z.object({
    amount: z
        .string()
        .min(1, { message: 'validation.invalidNumber' })
        .refine(
            (value) => Number.isFinite(Number(value)) && !Number.isNaN(Number(value)),
            'validation.invalidNumber'
        )
        .refine((value) => Number(value) >= Number(MIN_BUY_AMOUNT), 'validation.minimalAmount'),
});

export const BuyTokenWidget = ({
    coin,
    defaultAmount = '1',
    floatingButton,
}: BuyTokenWidgetProps) => {
    const { tonBalance } = useUser();
    const stringTonBalance = parseFloat(parseValue(tonBalance ?? 0n, 9)).toLocaleString('en', {
        maximumFractionDigits: 8,
    });
    const [amount, setAmount] = useState(defaultAmount);
    const [isLoading, setIsLoading] = useState(false);
    const [coinsAmount, setCoinsAmount] = useState(['0', '0']);
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const { handleBlur, handleFocus } = useKeyboardOpen();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const scrollToBuyButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsButtonVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        observer.observe(buttonRef.current as Element);
        return () => {
            observer.disconnect();
        };
    }, [buttonRef.current]);

    useAutoFetch(fetchTonBalance);

    const debouncedFetchTonBalance = useMemo(
        () =>
            debounce(async () => {
                setIsLoading(true);
                await fetchTonBalance();
                setIsLoading(false);
            }, debounceTime),
        [fetchTonBalance, setIsLoading]
    );

    const buySlippage = useSlippage('buy');

    const getCoinsForTons = useCallback(
        async (amount: NumericString) => {
            if (!amount || !bigPumpSdk || !coin.address) {
                return;
            }
            const { amount: coins, minAmount } =
                coin.status === CoinStatus.LiquiditySent
                    ? await stonfiV2Sdk.estimateCoinsForTons({
                          routerJettonWalletAddress: coin.routerJettonWalletAddress,
                          routerPtonWalletAddress: coin.routerPtonWalletAddress,
                          coinId: coin.id,
                          slippagePercent: buySlippage,
                          tons: amount,
                          poolAddress: coin.poolAddress ?? undefined,
                      })
                    : await bigPumpSdk.estimateCoinsForTons(
                          Address.parse(coin.address),
                          amount,
                          buySlippage
                      );

            setCoinsAmount([
                parseFloat(parseValue(coins, coin.decimals)).toLocaleString('en', {
                    maximumFractionDigits: 4,
                }),
                parseFloat(parseValue(minAmount, coin.decimals)).toLocaleString('en', {
                    maximumFractionDigits: 4,
                }),
            ]);
        },
        [coin, buySlippage]
    );

    const debouncedGetCoinsForTons = useMemo(
        () => debounce(getCoinsForTons, debounceTime),
        [getCoinsForTons]
    );

    useEffect(() => {
        debouncedGetCoinsForTons(amount as NumericString);
    }, [amount, buySlippage]);

    const { OperationDrawer, open } = useOperationDrawer();

    const validator = useMemo(() => createValidator(validationSchema), []);

    const validate = async (value: string) => {
        const errors = await validator({
            amount: value,
        });
        if (errors.amount) {
            setError(errors.amount);
            return false;
        }
        if (parseFloat(value) > parseFloat(parseValue(tonBalance ?? 0n, 9))) {
            setError(t('validation.notEnoughBalance'));
            return false;
        }
        setError('');
        return true;
    };

    useEffect(() => {
        validate(amount);
    }, [tonBalance]);

    const onSubmit = async () => {
        try {
            const validationResult = await validate(amount);
            if (!validationResult) {
                return;
            }
            buyCoins(amount as NumericString, coin, buySlippage);
            open();
        } catch (e) {
            console.log(e);
        }
    };

    const convertValueToNumberWithDot = () => {
        handleBlur();
        setAmount((prev) => {
            const replacedString = prev.replace(',', '.');
            validate(replacedString);
            return replacedString;
        });
    };

    const onChangeAmount = (value: string) => {
        debouncedFetchTonBalance();
        setAmount(value);
    };

    const setPresetAmount = (amount: string) => {
        onChangeAmount(amount);
        validate(amount);
    };

    return (
        <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1.5">
                <div className="flex items-center justify-between w-full">
                    <InputLabel text={t('buy-sell-token-widget-amount')} />
                    <SlippageSettings type="buy" />
                </div>
                <CurrencyInput
                    currency="TON"
                    value={amount}
                    onChange={onChangeAmount}
                    containerClassName="!bg-blue"
                    isBalanceLoading={isLoading}
                    balance={stringTonBalance}
                    validationError={error}
                    onBlur={convertValueToNumberWithDot}
                    onFocus={handleFocus}
                />
            </div>
            <div className="flex items-center gap-1.5">
                {[0.3, 1, 3, 10].map((percent) => (
                    <Button
                        key={percent}
                        variant="blueGradient"
                        className="w-12 h-8 text-xs border-black flex-1"
                        onClick={() => setPresetAmount(String(percent))}
                    >
                        {percent} TON
                    </Button>
                ))}
            </div>
            {error ? null : isLoading ? (
                <Skeleton size="lg" height="32px" />
            ) : (
                <p className="text-xs text-blue-sky whitespace-nowrap font-medium font-Wix text-center">
                    {t('token.youWillReceive')} ~{coinsAmount[0]} {coin.symbol}
                    <br />({t('min').toLowerCase()}: {coinsAmount[1]} {coin.symbol})
                </p>
            )}
            <WalletConnectionWrapper containerClassName="w-full">
                <BigColorButton
                    id="buy"
                    onClick={onSubmit}
                    disabled={error !== '' || !amount || parseFloat(amount) === 0 || isLoading}
                    ref={buttonRef}
                >
                    {t('token.buy').toUpperCase()}
                </BigColorButton>
            </WalletConnectionWrapper>
            <button
                className={classNames(
                    'fixed bottom-[100px] left-7 z-30 bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] rounded-xl create-token-shadow w-[calc(100%-3.5rem)]',
                    { hidden: isButtonVisible || !floatingButton }
                )}
                onClick={scrollToBuyButton}
            >
                <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                    <TextWithShadow className="italic">
                        {t('token.buy').toUpperCase()}
                    </TextWithShadow>
                </div>
            </button>
            {OperationDrawer}
        </div>
    );
};
