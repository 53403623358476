import { Skeleton } from 'common/components/skeleton.tsx';
import { EmptyList } from 'common/components/emptyList.tsx';
import classNames from 'classnames';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { Key } from 'react';

type Props<E> = {
    isFetching: boolean;
    canFetchMore: boolean;
    entities: E[];
    fetchEntities: () => void;
    keyField: keyof E;
    component: React.FC<{ entity: E }>;
    skeletonSize?: 'sm' | 'md' | 'lg';
    skeletonHeight?: number;
    emptyListColor?: string;
};

export const List = <E,>({
    isFetching,
    fetchEntities,
    entities,
    canFetchMore,
    keyField,
    component,
    skeletonHeight,
    skeletonSize = 'lg',
    emptyListColor,
}: Props<E>) => {
    const anchorRef = useScrollToFetchData(fetchEntities);

    const EntityComponent = component;
    return (
        <div className="flex flex-col gap-3">
            {isFetching && (
                <>
                    {[...Array(5)].map((_, ind) => (
                        <Skeleton size={skeletonSize} height={skeletonHeight} key={ind} />
                    ))}
                </>
            )}
            {!isFetching && !entities.length && <EmptyList color={emptyListColor} />}
            {entities.map((enitity) => (
                <EntityComponent entity={enitity} key={enitity[keyField] as Key} />
            ))}
            <div
                className={classNames('w-full flex flex-col gap-3', {
                    [`hidden`]: !canFetchMore,
                })}
                ref={anchorRef}
            >
                {[...Array(5)].map((_, ind) => (
                    <Skeleton size={skeletonSize} height={skeletonHeight} key={ind} />
                ))}
            </div>
        </div>
    );
};
