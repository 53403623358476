import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { BaseEntityStore } from 'common/constants/types.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { Address } from '@ton/ton';
import { parseValue } from 'common/utils/parseValue.ts';
import { getSdk, getStonfiV2Sdk } from 'bigPumpSdk/sdk.store.ts';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { $user } from 'user/user.store.ts';
import { bugsnagClient } from 'bugsnag';
import { gtag } from 'gtag.ts';
import { OLD_MASTER_ADDRESS } from 'common/constants';
import { navigation } from 'common/utils/routeUtils.ts';
import { tryCatch } from 'tryCatch';

export enum CoinStatus {
    Init = 'init', // created on backend
    Active = 'active', // deployed on chain
    Disabled = 'disable',
    LiquiditySent = 'liquidity-sent', // liquidity sent from the ton fun
}

export type Coin = {
    id: string;
    name: string;
    symbol: string;
    description: string;
    imageUrl: string;
    imageName: string;
    createdAt: string;
    status: CoinStatus; // ???
    user: {
        username: string;
    };
    authorAddress: string;
    updatedAt: string;
    address: string;
    decimals: number;
    tgChannel: null | string;
    tgChat: null | string;
    twitter: null | string;
    website: null | string;
    refId: string;
    chainCreatedAt: string;
    tonBalance: string;
    hotMeasure: string;
    hotMeasureUpdated: string;
    marketCap: string;
    totalSupply: string;
    bclSupply: string;
    bondingCurveProgress: string;
    availableToBuy: string | null;
    tonLiqCollected: string;
    tonSupply: string;
    currentPrice: string | null;
    bondingCurveProgressTon: string;
    liqCurrentUsdPrice?: string;
    liqUsdMarketCap?: string;
    masterAddress: string;
    priceChange1H: string;
    holderAmount: string;
    noteAmount: string;
    authorBalanceInTON: string;
    routerAddress: string;
    routerPtonWalletAddress: string;
    routerJettonWalletAddress: string;
    poolAddress?: string | null;
    liqSentAt: string;
    userJettonAmount?: string;
    jettonInTonAmount?: string;
};

interface CoinState extends BaseEntityStore {
    coin: Coin;
    coinBalance?: NumericString;
    isUnlocked: boolean | null;
}

type CoinsStore = Record<Coin['id'], CoinState>;

const defaultState = {
    isLoading: false,
    isFetched: false,
    isUnlocked: null,
};

export const $coin = map<CoinsStore>({});

// actions

const getCoinFromStore = (coinId: Coin['id']) => {
    return $coin.get()[coinId] ?? defaultState;
};

const updateCoin = action(
    $coin,
    'updateCoin',
    <K extends keyof CoinState>(
        store: typeof $coin,
        coinId: Coin['id'],
        field: K,
        value: CoinState[K]
    ) => {
        const currentState = getCoinFromStore(coinId);
        $coin.setKey(coinId, {
            ...currentState,
            [field]: value,
        });
    }
);

export const fetchCoinPriceById = action(
    $coin,
    'fetchCoinPriceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = getCoinFromStore(coinId).coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinPriceById');
            setTimeout(() => fetchCoinPriceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const coinPrice = await bigPumpSdk.getCoinPrice(currentCoin.address);

                const decimal = currentCoin.masterAddress === OLD_MASTER_ADDRESS ? 18 : 9;
                updateCoin(coinId, 'coin', {
                    ...currentCoin,
                    currentPrice: parseValue(coinPrice, decimal),
                });
            } catch (e) {
                console.log('error while fetching coin price');
            }
        }
    }
);

export const fetchCoinBalanceById = action(
    $coin,
    'fetchCoinBalanceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = getCoinFromStore(coinId).coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinBalanceById');
            setTimeout(() => fetchCoinBalanceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const balance = await bigPumpSdk.getUserCoinBalance(currentCoin.address);

                updateCoin(coinId, 'coinBalance', parseValue(balance, 9));
            } catch {
                // store.setKey('coinBalance', 0);
            }
        }
    }
);

export const fetchCoinUnlocked = action(
    $coin,
    'fetchCoinUnlocked',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = getCoinFromStore(coinId).coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinUnlocked');
            return;
        }

        if (bigPumpSdk) {
            try {
                const unlocked = await bigPumpSdk.checkIsLocked({
                    coinAddress: currentCoin.address,
                });
                updateCoin(coinId, 'isUnlocked', unlocked ?? null);
            } catch {
                updateCoin(coinId, 'isUnlocked', false);
            }
        }
    }
);

export const fetchCoinById = action($coin, 'fetchCoinById', async (store, coinId: Coin['id']) => {
    updateCoin(coinId, 'isLoading', true);

    try {
        const response = await authorizedFetch(routes.coinById + `/${coinId}`);

        if (response.ok) {
            const { currentPrice, ...data } = (await response.json()) as Coin;

            const currentCoin = getCoinFromStore(coinId).coin;

            updateCoin(coinId, 'coin', {
                ...data,
                currentPrice: currentPrice ?? currentCoin?.currentPrice ?? null,
            });

            if (!store.get().isFetched) {
                fetchCoinBalanceById(coinId);
            }
            if (data.status === CoinStatus.LiquiditySent) {
                fetchCoinUnlocked(coinId);
            }
            updateCoin(coinId, 'isFetched', true);
        }
    } catch (e) {
        console.error('fetchKingOfHill error', e);
    } finally {
        updateCoin(coinId, 'isLoading', false);
    }
});

export const buyCoins = action(
    $coin,
    'buyCoins',
    async (store, amount: NumericString, coin: Coin, slippagePercent: bigint) => {
        const bigPumpSdk = getSdk();
        const stonfiV2Sdk = getStonfiV2Sdk();

        if (!bigPumpSdk) {
            console.error('bigPumpSdk not found on buyCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            console.error('user not fetched on buyCoins');
            return;
        }

        try {
            gtag('meme_transaction', {
                wallet_address: tonConnectUI.account?.address ?? 'unknown',
                meme_name: coin?.name.trim() ?? 'unknown',
                action_type: 'buy',
                transaction_value: amount,
                fee: 'unknown',
            });
            if (coin.status === CoinStatus.LiquiditySent) {
                await stonfiV2Sdk.buy({
                    routerAddress: coin.routerAddress,
                    routerPtonWalletAddress: coin.routerPtonWalletAddress,
                    routerJettonWalletAddress: coin.routerJettonWalletAddress,
                    coinAddress: coin.address,
                    coinId: coin.id,
                    userId: user.id,
                    slippagePercent,
                    tons: amount as NumericString,
                });
            } else {
                await bigPumpSdk.buy({
                    coinAddress: coin.address,
                    tons: amount as NumericString,
                    userId: user.id,
                    slippagePercent,
                });
            }
            gtag('meme_transaction_success', {
                wallet_address: tonConnectUI.account?.address ?? 'unknown',
                meme_name: coin?.name.trim() ?? 'unknown',
                action_type: 'buy',
                transaction_value: amount,
                fee: 'unknown',
            });
        } catch (e) {
            console.log('error while buying coins', e);
        }
    }
);

export const sellCoins = action(
    $coin,
    'sellCoins',
    async (
        store,
        {
            amount,
            coin,
            slippagePercent,
            tonsAmount,
        }: {
            amount: NumericString;
            coin: Coin;
            slippagePercent: bigint;
            tonsAmount: string;
        }
    ) => {
        const bigPumpSdk = getSdk();
        const stonfiV2Sdk = getStonfiV2Sdk();

        if (!bigPumpSdk) {
            bugsnagClient.notify('bigpump sdk in not initialized on sellCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            bugsnagClient.notify('user not fetched on sellCoins');
            console.error('user not fetched on sellCoins');
            return;
        }

        if (!parseFloat(amount)) {
            bugsnagClient.notify('0 value for sell was received');
            throw new Error('No value for sell');
        }
        try {
            tryCatch(() => {
                gtag('meme_transaction', {
                    wallet_address: tonConnectUI.account?.address ?? 'unknown',
                    meme_name: coin?.name.trim() ?? 'unknown',
                    action_type: 'sell',
                    transaction_value: tonsAmount,
                    fee: 'unknown',
                });
            });
            if (coin.status === CoinStatus.LiquiditySent) {
                await stonfiV2Sdk.sell({
                    routerAddress: coin.routerAddress,
                    routerPtonWalletAddress: coin.routerPtonWalletAddress,
                    routerJettonWalletAddress: coin.routerJettonWalletAddress,
                    coinAddress: coin.address,
                    coinId: coin.id,
                    userId: user.id,
                    slippagePercent,
                    coins: amount as NumericString,
                });
            } else {
                await bigPumpSdk.sell({
                    coinAddress: coin.address,
                    coins: amount as NumericString,
                    userId: user.id,
                    slippagePercent,
                });
            }
            tryCatch(() => {
                gtag('meme_transaction_success', {
                    wallet_address: tonConnectUI.account?.address ?? 'unknown',
                    meme_name: coin?.name.trim() ?? 'unknown',
                    action_type: 'sell',
                    transaction_value: tonsAmount,
                    fee: 'unknown',
                });
            });
        } catch (e: any) {
            bugsnagClient.notify(e);
            console.log('error while selling coins', e);
        }
    }
);

export const unlockTokens = action($coin, 'unlockTokens', async (store, coinId: Coin['id']) => {
    const coinAddress = getCoinFromStore(coinId).coin?.address;
    const bigPumpSdk = getSdk();

    if (!bigPumpSdk) {
        console.error('bigPumpSdk not found on unlockTokens');
        return;
    }

    if (!coinAddress) {
        console.error('coin address not found on unlockTokens');
        return;
    }

    try {
        await bigPumpSdk.unlock({
            coinAddress,
        });

        fetchCoinUnlocked(coinId);
    } catch (e) {
        console.log('error while unlocking tokens', e);
    }
});

export const getCoinIdByAddress = action(
    $coin,
    'getCoinIdByAddress',
    async (store, address: string) => {
        try {
            const response = await authorizedFetch(
                routes.coinByAddress(Address.parse(address).toRawString())
            );

            if (response.ok) {
                const data = (await response.json()) as { id: string };

                navigation.tokenTrade(data.id)();
                return;
            }
        } catch (e) {
            console.log('getCoinIdByAddress error', e);
        }
    }
);

// selectors

const selectCoinById = computed($coin, (store) => store);

// hooks

export const useCoinById = (coinId: Coin['id']) => {
    const coin = useStore(selectCoinById)[coinId] ?? defaultState;

    return {
        ...coin,
        isFetching: coin.isLoading && !coin.isFetched,
    };
};
