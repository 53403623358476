import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import WebApp from '@twa-dev/sdk';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { tryCatch } from 'tryCatch.ts';
import { TelegramLoginButton } from 'telegram/LoginWithTelegramButton.tsx';
import { TelegramAuthUser } from 'telegram/config.ts';
import { routes, unauthorizedFetch } from 'common/utils/fetchUtils.ts';
import { setTgAuthInitData, useTgAuth } from 'desktop/tgAuth.store.ts';
import { useState } from 'react';
import { DesktopModal } from 'desktop/components/desktopModal.tsx';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { sendReferralCode } from 'frens/frens.store.ts';

export const DesktopLoginPage = () => {
    const [openTgModal, setOpenTgModal] = useState(false);
    const savedInitData = useTgAuth();
    const openTonConnectModal = () => {
        tonConnectUI.openModal();
    };

    const connectPocketFi = async (force?: boolean) => {
        if (!savedInitData && !force) {
            setOpenTgModal(true);
            return;
        }
        tryCatch(() => {
            tonConnectUI.connector.connect({ jsBridgeKey: 'pocketfi' });
        });
    };

    const loginWithTg = async (user: TelegramAuthUser) => {
        const refCode = parseStartParam(location.search)?.referralCode;
        const response = await unauthorizedFetch(routes.tgWidgetLogin, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authData: new URLSearchParams(user).toString(),
                refCode,
            }),
        });

        if (response.ok) {
            const json = (await response.json()) as {
                initData: string;
                user: {
                    id: number;
                    language_code: string;
                    first_name?: string;
                    last_name?: string;
                    username?: string;
                };
            };

            setTgAuthInitData(json.initData);
            setOpenTgModal(false);

            const startParams = parseStartParam(location.search);

            if (startParams?.referralCode) {
                await sendReferralCode(startParams.referralCode);
            }

            connectPocketFi(true);
        }
    };
    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#627DD5] grid grid-rows-5">
            <div className="row-span-1 bg-[#102F96] relative loginScreen__bg_top" />
            <div className="row-span-3 grid grid-cols-2 relative loginScreen__bg_center">
                <div className="w-fit self-center justify-self-end pr-10 ">
                    <div className="flex items-end justify-between gap-2">
                        <div
                            className=" text-white flex items-center gap-1.5 text-md w-fit font-semibold"
                            onClick={() =>
                                WebApp.openTelegramLink('https://t.me/pocketfi_bot/swap')
                            }
                        >
                            <span>by</span>
                            <img
                                src="/pocketfi_logo.svg"
                                alt="pocketfi"
                                className="w-5 h-5 min-w-5"
                            />
                            <span>PocketFi</span>
                        </div>
                        <img
                            src="/desktop/pump_logo.png"
                            alt="pump"
                            className="min-w-[1.75rem] w-32 h-32"
                        />
                    </div>
                    <TextWithShadow shadowWidth={2} className="italic text-[60px]">
                        BIG.PUMP
                    </TextWithShadow>
                </div>
                <div className="flex flex-col items-left justify-center gap-10 pl-10">
                    <button className="flex items-center gap-4" onClick={() => connectPocketFi()}>
                        <img
                            src="/desktop/user_icon.png"
                            alt="pocketfi"
                            className="w-24 aspect-square rounded-lg border-4 border-[#DADADA]"
                        />
                        <span className="text-[30px] text-white loginScreen__text_shadow">
                            Login with PocketFi Wallet
                        </span>
                    </button>

                    <button className="flex items-center gap-4" onClick={openTonConnectModal}>
                        <img
                            src="/desktop/flower.png"
                            alt="ton_connect"
                            className="w-24 aspect-square rounded-lg border-4 border-[#DADADA]"
                        />
                        <span className="text-[30px] text-white loginScreen__text_shadow">
                            Login with Ton Connect
                        </span>
                    </button>
                </div>
            </div>
            <div className="row-span-1 bg-[#102F96] relative loginScreen__bg_bottom" />
            <DesktopModal
                open={openTgModal}
                closable
                onClose={() => setOpenTgModal(false)}
                title="Two factor authentification"
            >
                <div className="flex flex-col items-center gap-5">
                    <p className="text-sm max-w-[25vw] text-left font-normal">
                        To login with PocketFi Wallet, please, first authorize with telegram
                    </p>
                    <TelegramLoginButton
                        userPic={false}
                        dataOnauth={loginWithTg}
                        botName={import.meta.env.VITE_BOT_NAME}
                    />
                </div>
            </DesktopModal>
        </div>
    );
};
