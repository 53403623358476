import { StonfiV2Sdk } from "bclSdkOriginal/BigPumpSdk"
import { NumericString } from "bclSdkOriginal/types"
import { tonConnectUI } from "tonConnect/configureTonConnect"


const sdk = new StonfiV2Sdk(tonConnectUI)
// "routerAddress": "0:c03f27bb1c03c0025e165e9cf98ce09d85fd87f61f50924cd143ee8ae6eb22b9",
//     "routerPtonWalletAddress": "0:c15e924172d00a95b00c5aa1f68933f204f7dd04f79067a086469d68281a9090",
//     "routerJettonWalletAddress": "0:507bcb4fad229a2cd95af4dc1bf3dbb8b3de58b6b7500bcc61e8c43eb50acbe8",
export function Test() {


    async function letsFuckingGo() {
        // buy
        // await sdk.buy({
        //     routerAddress: '0:c03f27bb1c03c0025e165e9cf98ce09d85fd87f61f50924cd143ee8ae6eb22b9',
        //     routerPtonWalletAddress: '0:c15e924172d00a95b00c5aa1f68933f204f7dd04f79067a086469d68281a9090',
        //     routerJettonWalletAddress: '0:507bcb4fad229a2cd95af4dc1bf3dbb8b3de58b6b7500bcc61e8c43eb50acbe8',
        //     coinAddress: '0:cdc664a4e30b91f978dfaecb98049de09f5e9982ab5ab305a56c3a7310610f4e',
        //     coinId: '251876',
        //     userId: '1',
        //     slippagePercent: 10n,
        //     tons: '00.1' as NumericString,
        // })
        // await sdk.sell({
        //     routerAddress: '0:c03f27bb1c03c0025e165e9cf98ce09d85fd87f61f50924cd143ee8ae6eb22b9',
        //     routerPtonWalletAddress: '0:c15e924172d00a95b00c5aa1f68933f204f7dd04f79067a086469d68281a9090',
        //     routerJettonWalletAddress: '0:507bcb4fad229a2cd95af4dc1bf3dbb8b3de58b6b7500bcc61e8c43eb50acbe8',
        //     coinAddress: '0:cdc664a4e30b91f978dfaecb98049de09f5e9982ab5ab305a56c3a7310610f4e',
        //     coinId: '251876',
        //     userId: '1',
        //     slippagePercent: 10n,
        //     coins: '900000' as NumericString,
        // })
    }
    
    return <button onClick={letsFuckingGo}>lets fucking go</button>
}
