import { Card } from 'common/components/card.tsx';
import { RadioSlider } from 'common/components/radioSlider.tsx';
import { useEffect, useState } from 'react';
import { CoinStatus, useCoinById } from 'coin/coin.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { BuyTokenWidget } from 'coin/components/buyTokenWidget.tsx';
import { SellTokenWidget } from 'coin/components/sellTokenWidget.tsx';
import { useTranslation } from 'i18n';
import { getCoinMarketCap } from 'coin/utils.ts';
import { fetchTonPrice, useTon } from 'main/main.store.ts';
import { GoldShareCoinButton } from 'coin/components/goldShareCoinButton.tsx';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { LiquidityProgress } from 'coin/tabs/liquidityProgress.tsx';
import { ChartWithControl } from 'coin/chart/chartWithControl.tsx';
import { TransactionHistory } from 'coin/transactions/transactionHistory.tsx';
import { UnlockTokens } from 'coin/components/unlockTokens.tsx';
import { CommunityNotesOnTradingTab } from 'coin/communityNotes/communityNotes.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { CoinStats } from 'coin/coinStats/coinStats.tsx';
import { PriceChange } from 'coin/components/priceChange.tsx';
import { useParams } from 'react-router';
import { NumericString } from 'bclSdkOriginal/types.ts';

const tokenTradeTabs = {
    buy: BuyTokenWidget,
    sell: SellTokenWidget,
};

export const TokenTradeTab = () => {
    const { price } = useTon();
    const { tokenId: coinId } = useParams();

    if (!coinId) {
        throw new Error('Should be used on token page');
    }

    useBackButton({
        from: `/token/${coinId}/trade`,
        callback: navigation.mainPage,
    });

    useEffect(() => {
        if (!price) {
            fetchTonPrice();
        }
    }, [price]);

    const { coin, coinBalance } = useCoinById(coinId);
    const [tradeType, setTradeType] = useState<'buy' | 'sell'>('buy');
    const { t } = useTranslation();

    if (!coin) {
        return <Skeleton size="lg" />;
    }

    const TradeWidget = tokenTradeTabs[tradeType];

    return (
        <>
            <div className="bg-[#002366] flex flex-col gap-[1px] border-2 border-black pb-2">
                <div className="bg-blue flex gap-2.5 items-start p-2.5">
                    <img
                        src={coin.imageUrl}
                        alt={coin.imageName}
                        className="w-20 aspect-square object-contain"
                    />
                    <div className="flex flex-col gap-2.5">
                        <div className="flex items-center gap-1">
                            <span className="text-white">{coin.name}</span>
                            <span className="text-sm text-yellow font-normal">{coin.symbol}</span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-xs text-white/80 font-normal">
                                {t('market-cap')}
                            </span>
                            <div className="flex items-center gap-2">
                                <TokenMarketCap onlyNumber value={getCoinMarketCap(coin, price)} />
                                <PriceChange
                                    value={parseFloat(coin.priceChange1H)}
                                    className="text-sm"
                                    colors={{
                                        positive: 'text-oxid-green',
                                        negative: 'text-red',
                                    }}
                                    arrow
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex ml-auto">
                        <GoldShareCoinButton coinId={coin.id} />
                    </div>
                </div>
            </div>
            <LiquidityProgress coin={coin} />
            <Card className="!bg-white !p-1 !pb-2">
                <ChartWithControl coin={coin} />
            </Card>
            <Card className="flex flex-col gap-3 !p-4 bg-gradient-to-b from-white/40 to-transparent !bg-blue">
                <RadioSlider
                    value={tradeType}
                    onChange={setTradeType}
                    options={[
                        {
                            value: 'buy',
                            label: (
                                <div className="flex items-center gap-1.5">
                                    <img src="/green_ball.png" alt="" className="w-4 h-4 -mt-0.5" />
                                    <span>{t('token.buy')}</span>
                                </div>
                            ),
                        },
                        {
                            value: 'sell',
                            label: (
                                <div className="flex items-center gap-1.5">
                                    <img src="/red_ball.png" alt="" className="w-4 h-4 -mt-0.5" />
                                    <span>{t('token.sell')}</span>
                                </div>
                            ),
                        },
                    ]}
                />
                <TradeWidget
                    coin={coin}
                    coinBalance={coinBalance ?? ('100' as NumericString)}
                    floatingButton
                />
            </Card>
            {coin.status === CoinStatus.LiquiditySent ? <UnlockTokens coinId={coinId} /> : null}
            <Card className="!bg-blue">
                <CoinStats coinId={coinId} />
            </Card>
            <CommunityNotesOnTradingTab tokenId={coinId} count={parseFloat(coin.noteAmount)} />
            <TransactionHistory tokenId={coinId} />
        </>
    );
};
