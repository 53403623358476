import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { SortListType } from 'main/coinsList.store.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

type FetchCoinsListParams = {
    sortType: SortListType;
    cursor?: string;
    limit?: string;
};

export const fetchCoinsList = ({ sortType, cursor, limit = '10' }: FetchCoinsListParams) => {
    const params: Record<string, string> = {
        sortType,
        limit,
        ...(cursor
            ? {
                  cursor,
              }
            : {}),
    };

    if (sortType === SortListType.MyTokens) {
        params.walletAddress = tonConnectUI.account?.address as string;
    }

    return authorizedFetch(routes.coins + '?' + new URLSearchParams(params).toString());
};
