import { ICCard } from 'inviteContest/components/icCard.tsx';
import { ICButton } from 'inviteContest/components/icButton.tsx';
import { TimerUntilEnd } from 'inviteContest/components/timerUntilEnd.tsx';
import { TotalPrizeFund } from 'inviteContest/components/totalPrizeFund.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { ContestUserWidget } from 'inviteContest/components/contestUserWidget.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchUserContest, useUserContestData } from 'inviteContest/contest.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import { DisplayScoreCriteria } from 'inviteContest/components/displayScoreCriteria.tsx';
import { useTranslation } from 'i18n';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { useUser } from 'user/user.store.ts';
import { CopyButton } from 'common/components/copyButton.tsx';
import { useMainButton } from 'common/hooks/useMainButton.ts';
import { useEffect } from 'react';

export const JoinContestPage = () => {
    const { t } = useTranslation();
    const { me, isFetched } = useUserContestData();

    useAutoFetch(fetchUserContest);

    const { user } = useUser();
    const referralLink = generateReferralLink({ user, appName: 'Contest' });

    useBackButton({
        from: '/invite-contest/join',
        callback: navigation.mainPage,
    });

    const { show, hide } = useMainButton({
        text: t('invite-contest.trade'),
        onClick: navigation.mainPage,
        color: '#ffb000',
        text_color: '#000000',
    });

    useEffect(() => {
        show();
        return hide;
    }, []);

    const scoreDiff = parseFloat(normalizedToFixed(me.points - me.prevPoints, 2));

    return (
        <div className="bg-invite-contest-primary h-screen flex flex-col items-center py-3 px-4 pb-3 font-Wix overflow-y-auto">
            <div className="flex items-center justify-between gap-2 w-full">
                <TimerUntilEnd />
                <TotalPrizeFund />
            </div>
            <ContestUserWidget
                username={me.username ?? 'anonymous'}
                rating={me.rank}
                prevRating={me.prevRank}
                randomProb={me.randomProb}
                isWinner={me.isWinner}
            />
            <div className="mt-2 bg-white rounded-t-[24px] rounded-b-[4px] py-6 px-5 w-full flex items-center justify-between shadow">
                <span className="text-md text-invite-contest-dark-blue">
                    {t('invite-contest.your-score')}
                </span>
                {isFetched ? (
                    me.blocked === 'bot' ? (
                        <p className="text-md font-bold text-dark-red flex-1 text-right truncate">
                            {t('invite-contest.you-are-blocked').toUpperCase()}
                        </p>
                    ) : (
                        <div className="flex items-center gap-1.5">
                            <span className="text-2xl font-bold font-Manrope">
                                {normalizedToFixed(me.points, 2)}
                            </span>
                            {!!scoreDiff && (
                                <span className="text-sm text-[#5B8F28] font-bold">{`+${scoreDiff}`}</span>
                            )}
                        </div>
                    )
                ) : (
                    <Skeleton size="sm" className="w-10" />
                )}
            </div>
            <div className="mt-2 bg-white rounded-b-[24px] rounded-t-[4px] py-3 px-5 w-full flex items-center justify-between gap-3 shadow-[0px_20px_40px_0px_#88878740]">
                <DisplayScoreCriteria
                    title={t('invite-contest.frens-volume')}
                    value={me.bigpumpAmountUSD ?? null}
                    prevValue={me.prevBigpumpAmountUSD ?? null}
                    icon="/tether.png"
                />
                <div className="h-full w-[1px] bg-[#2C2C2C] opacity-10" />
                <DisplayScoreCriteria
                    title={t('invite-contest.personal-volume')}
                    value={me.bigpumpSelfUSD ?? null}
                    prevValue={me.prevBigpumpSelfUSD ?? null}
                    icon="/tether.png"
                />
            </div>
            <div className="flex flex-col items-center gap-2 w-full">
                <div className="flex items-center w-full mt-5 gap-2">
                    <button className="flex-1" onClick={navigation.contestRewards}>
                        <ICCard className="flex items-center gap-1.5 p-3 rounded-xl">
                            <img
                                src="/inviteContest/cup.gif"
                                alt=""
                                className="h-6 aspect-square object-cover rounded-lg"
                            />
                            <span className="text-sm xs:text-base">
                                {t('invite-contest.rewards')}
                            </span>
                        </ICCard>
                    </button>
                    <button className="flex-1" onClick={navigation.contestHowTo}>
                        <ICCard className="flex items-center gap-1.5 p-3 rounded-xl">
                            <img
                                src="/inviteContest/goku.gif"
                                alt=""
                                className="h-6 aspect-square object-cover rounded-lg"
                            />
                            <span className="text-sm xs:text-base">
                                {t('invite-contest.how-to-start')}
                            </span>
                        </ICCard>
                    </button>
                </div>
                <button className="flex-1 w-full" onClick={navigation.contestLeaderboard}>
                    <ICCard className="flex justify-center items-center gap-1.5 p-3 rounded-xl">
                        <span className="text-sm xs:text-base">
                            {t('invite-contest.leaderboard')}
                        </span>
                    </ICCard>
                </button>
            </div>
            <div className="flex flex-col gap-2 w-full mt-auto">
                <div className="flex items-stretch h-12 w-full gap-2">
                    <ICButton
                        className="bg-dark-yellow flex-1 leading-none shadow h-12"
                        onClick={() =>
                            openShareWithFriendsLink(referralLink, t('frens.invite.message'))
                        }
                    >
                        {t('invite-contest.invite-a-fren')}
                    </ICButton>
                    <CopyButton text={referralLink}>
                        <ICButton className="bg-dark-yellow aspect-square w-12 h-12 flex items-center justify-center shadow">
                            <img src="/content_copy.svg" alt="copy" className="w-6 aspect-square" />
                        </ICButton>
                    </CopyButton>
                </div>
            </div>
        </div>
    );
};
