import classNames from 'classnames';
import { useTranslation } from 'i18n';

const TgSvg = ({ className }: { className?: string }) => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.813 2.07013L12.6437 12.3004C12.4801 13.0225 12.0533 13.2021 11.4467 12.8621L8.14149 10.4264L6.54667 11.9603C6.37024 12.1368 6.22258 12.2844 5.88242 12.2844L6.11986 8.91813L12.2458 3.3826C12.5122 3.14517 12.1881 3.01357 11.8319 3.25104L4.25867 8.0196L0.998331 6.99917C0.289143 6.77773 0.276331 6.28995 1.14592 5.94982L13.8985 1.03685C14.4889 0.815447 15.0055 1.16838 14.813 2.07013Z"
                fill="currentColor"
            />
        </svg>
    );
};

const TwitterSvg = ({ className }: { className?: string }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.48891 6.77143L15.3142 0H13.9338L8.87569 5.87954L4.8358 0H0.17627L6.28537 8.8909L0.17627 15.9918H1.55676L6.89824 9.78279L11.1647 15.9918H15.8242L9.48857 6.77143H9.48891ZM7.59814 8.96923L6.97916 8.0839L2.05416 1.03921H4.17451L8.14904 6.7245L8.76802 7.60983L13.9344 14.9998H11.8141L7.59814 8.96957V8.96923Z"
                fill="currentColor"
            />
        </svg>
    );
};

const WebSvg = ({ className }: { className?: string }) => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.33317 14.6673C12.0151 14.6673 14.9998 11.6825 14.9998 8.00065C14.9998 4.31875 12.0151 1.33398 8.33317 1.33398C4.65127 1.33398 1.6665 4.31875 1.6665 8.00065C1.6665 11.6825 4.65127 14.6673 8.33317 14.6673Z"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1.6665 8H14.9998"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.33317 1.33398C10.0007 3.15955 10.9483 5.52867 10.9998 8.00065C10.9483 10.4726 10.0007 12.8417 8.33317 14.6673C6.66565 12.8417 5.718 10.4726 5.6665 8.00065C5.718 5.52867 6.66565 3.15955 8.33317 1.33398Z"
                stroke="currentColor"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export type SocialType = 'tgChannel' | 'tgChat' | 'twitter' | 'website';

const socialSvgs = {
    tgChannel: TgSvg,
    tgChat: TgSvg,
    twitter: TwitterSvg,
    website: WebSvg,
} as const;

const socialLabels = {
    tgChannel: 'createToken.telegram',
    tgChat: 'createToken.telegramChat',
    twitter: 'createToken.twitter',
    website: 'createToken.website',
} as const;

const statusClasses = {
    active: 'bg-white border-white !border-transparent',
    default: 'opacity-40 text-white border-white',
    filled: 'bg-gradient-to-r from-[#B0FF5C] to-[#FFB000] !border-transparent !text-black !bg-transparent',
    error: '!bg-red !text-black !  border-transparent',
    dialogCorner: 'rounded-bl-sm',
};

export const Social = ({
    type,
    active,
    filled,
    onClick,
    error,
    dialogCorner,
}: {
    type: SocialType;
    active: boolean;
    filled: boolean;
    onClick: (value: SocialType) => void;
    error?: boolean;
    dialogCorner?: boolean;
}) => {
    const { t } = useTranslation();
    const Icon = socialSvgs[type];
    return (
        <div
            className={classNames(
                `border p-1.5 rounded-lg flex-1 flex items-center justify-center gap-1.5 ${active ? statusClasses.active : statusClasses.default}`,
                {
                    [statusClasses.filled]: filled && !error,
                    [statusClasses.error]: error,
                    [statusClasses.dialogCorner]: dialogCorner,
                }
            )}
            onClick={() => onClick(type)}
        >
            <Icon />
            <span className="text-xs">{t(socialLabels[type]).toUpperCase()}</span>
        </div>
    );
};
