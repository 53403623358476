import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { pocketFiAuthorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import WebApp from '@twa-dev/sdk';

export type ICParticipant = {
    firstName?: string | null;
    username?: string | null;
    id: string;
    userId: number;
    bigpumpAmountUSD: number;
    bigpumpSelfUSD: number;
    points: number;
    rank: number;
    prevBigpumpAmountUSD: number;
    prevBigpumpSelfUSD: number;
    prevPoints: number;
    prevRank: number;
    isWinner?: boolean;
};

export type ICUserInfo = ICParticipant & {
    randomProb: number;
    blocked?: 'bot';
};

interface IContestStore extends BaseEntityStore {
    isParticipantsLoading: boolean;
    isParticipantsFetched: boolean;
    participants: ICParticipant[];
    canFetchMoreParticipants?: boolean;
    me: ICUserInfo | null;
}

export const $contest = map<IContestStore>({
    isLoading: false,
    isFetched: false,
    me: null,
    canFetchMoreParticipants: true,
    isParticipantsLoading: false,
    isParticipantsFetched: false,
    participants: [],
});

export const fetchUserContest = action($contest, 'fetchUserContest', async (store) => {
    store.setKey('isLoading', true);
    try {
        const response = await pocketFiAuthorizedFetch(routes.contestMe);

        if (!response.ok) {
            throw new Error('Error fetching user contest');
        }

        const data = (await response.json()) as { selfContestData: ICUserInfo };

        if (!data.selfContestData || !data.selfContestData.userId) {
            await pocketFiAuthorizedFetch(routes.contestInit, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            fetchUserContest();
            return;
        }

        store.setKey('me', data.selfContestData);
        store.setKey('isFetched', true);

        WebApp.CloudStorage.getItem('tradeContest2', (err, data) => {
            if (!data && !err) {
                WebApp.CloudStorage.setItem(
                    'tradeContest2',
                    JSON.stringify({
                        joined: true,
                    })
                );
            }
        });
    } catch (e) {
        console.error('Error fetching user contest', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const fetchContestLeaderboard = action(
    $contest,
    'fetchContestLeaderboard',
    async (store) => {
        store.setKey('isParticipantsLoading', true);
        try {
            const { participants } = store.get();

            const search = new URLSearchParams();

            if (participants.length > 0) {
                const lastParticipant = participants[participants.length - 1];
                search.set('fromRank', String(lastParticipant.rank));
                search.set('fromUserId', String(lastParticipant.userId));
            }

            const response = await pocketFiAuthorizedFetch(
                routes.contestLeaderboard + '?' + search.toString()
            );

            if (!response.ok) {
                throw new Error('Error fetching contest leaderboard');
            }

            const data = (await response.json()) as { leaderboard: ICParticipant[] };

            if (data.leaderboard.length === 0) {
                store.setKey('canFetchMoreParticipants', false);
            }

            store.setKey('participants', [...participants, ...data.leaderboard]);
            store.setKey('isParticipantsFetched', true);
        } catch (e) {
            console.error('Error fetching contest leaderboard', e);
        } finally {
            store.setKey('isParticipantsLoading', false);
        }
    }
);

export const clearParticipants = action($contest, 'clearParticipants', (store) => {
    store.setKey('participants', []);
    store.setKey('canFetchMoreParticipants', true);
});

// selectors

const selectUserContestData = computed($contest, (store) => ({
    me: {
        firstName: store.me?.firstName ?? WebApp.initDataUnsafe.user?.first_name ?? 'anonymous',
        username: store.me?.username ?? WebApp.initDataUnsafe.user?.username ?? 'anonymous',
        bigpumpAmountUSD: store.me?.bigpumpAmountUSD ?? 0,
        bigpumpSelfUSD: store.me?.bigpumpSelfUSD ?? 0,
        points: store.me?.points ?? 0,
        rank: store.me?.rank ?? 0,
        prevBigpumpAmountUSD: store.me?.prevBigpumpAmountUSD ?? 0,
        prevBigpumpSelfUSD: store.me?.prevBigpumpSelfUSD ?? 0,
        prevPoints: store.me?.prevPoints ?? 0,
        prevRank: store.me?.prevRank ?? 0,
        blocked: store.me?.blocked ?? null,
        randomProb: store.me?.randomProb ?? 0,
        isWinner: store.me?.isWinner ?? undefined,
    },
    isLoading: store.isLoading,
    isFetched: store.isFetched,
}));

const selectContestLeaderboard = computed($contest, (store) => ({
    participants: store.participants,
    isLoading: store.isParticipantsLoading,
    isFetched: store.isParticipantsFetched,
    canFetchMore: store.canFetchMoreParticipants,
}));

export const useUserContestData = () => useStore(selectUserContestData);

export const useContestLeaderboard = () => useStore(selectContestLeaderboard);
