import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { TelegramAuthUser } from 'telegram/config.ts';
import { useTranslation } from 'i18n';

type Props = {
    botName?: string;
    buttonSize?: 'large' | 'medium' | 'small';
    cornerRadius?: number;
    requestAccess?: string;
    userPic?: boolean;
    dataOnauth?: (user: TelegramAuthUser) => void;
    dataAuthUrl?: string;
    lang?: string;
    widgetVersion?: number;
    className?: string;
};

export const TelegramLoginButton = ({
    botName = 'aqua_cinnamon_bot',
    buttonSize = 'large',
    cornerRadius = 20,
    requestAccess = 'write',
    userPic = true,
    dataAuthUrl,
    lang,
    widgetVersion = 22,
    className,
    children,
    dataOnauth,
}: PropsWithChildren<Props>) => {
    const { lang: language } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.TelegramLoginWidget = {
            dataOnauth: async (user) => {
                dataOnauth?.(user);
            },
        };

        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?' + widgetVersion;
        script.setAttribute('data-telegram-login', botName);
        script.setAttribute('data-size', buttonSize);
        if (cornerRadius !== undefined) {
            script.setAttribute('data-radius', String(cornerRadius));
        }
        script.setAttribute('data-request-access', requestAccess);
        script.setAttribute('data-userpic', String(userPic));
        script.setAttribute('data-lang', lang ?? language);
        if (dataAuthUrl !== undefined) {
            script.setAttribute('data-auth-url', dataAuthUrl);
        } else {
            script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnauth(user)');
        }
        script.async = true;
        ref.current?.appendChild(script);
    }, []);

    return (
        <div className={className} ref={ref}>
            {children}
        </div>
    );
};
