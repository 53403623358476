import { ContestUserWidget } from 'inviteContest/components/contestUserWidget.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { ICCard } from 'inviteContest/components/icCard.tsx';
import {
    clearParticipants,
    fetchContestLeaderboard,
    fetchUserContest,
    ICParticipant,
    useContestLeaderboard,
    useUserContestData,
} from 'inviteContest/contest.store.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { getRandomAvatarForContest, getRewardForPosition } from 'inviteContest/utils.ts';
import classNames from 'classnames';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { useCleanupEffect } from 'common/hooks/useCleanupEffect.ts';
import { useTranslation } from 'i18n';

export const ContestLeaderboard = () => {
    const { participants, isFetched, canFetchMore } = useContestLeaderboard();
    const { me } = useUserContestData();

    useBackButton({
        callback: navigation.joinContest,
        from: '/inviteContest/leaderboard',
    });

    useAutoFetch(fetchUserContest);

    useAutoFetch(fetchContestLeaderboard);

    useCleanupEffect(clearParticipants);

    const anchorRef = useScrollToFetchData(fetchContestLeaderboard);

    return (
        <div className="bg-invite-contest-primary h-screen flex flex-col items-center gap-2 py-3 px-4 pb-6 font-Wix overflow-y-auto">
            <ContestUserWidget
                username={me.username}
                rating={me.rank}
                prevRating={me.prevRank}
                points={me.points}
                randomProb={me.randomProb}
                isWinner={me.isWinner}
            />
            <ICCard className="w-full flex flex-col py-2 pr-5 pl-2">
                {isFetched ? (
                    participants.map((participant) => (
                        <Participant key={participant.id} participant={participant} />
                    ))
                ) : (
                    <div className="w-full flex flex-col">
                        <div className="border-b border-dark/10 pb-2 mb-2">
                            <Skeleton size="lg" className="rounded-xl" />
                        </div>
                        <div className="border-b border-dark/10 pb-2 mb-2">
                            <Skeleton size="lg" className="rounded-xl" />
                        </div>
                        <div className="border-b border-dark/10 pb-2 mb-2">
                            <Skeleton size="lg" className="rounded-xl" />
                        </div>
                        <div className="border-b border-dark/10 pb-2 mb-2">
                            <Skeleton size="lg" className="rounded-xl" />
                        </div>
                        <Skeleton size="lg" className="rounded-xl" />
                    </div>
                )}
                <div
                    className={classNames('w-full flex flex-col mt-3', {
                        [`hidden`]: !canFetchMore || !isFetched,
                    })}
                    ref={anchorRef}
                >
                    <div className="border-b border-dark/10 pb-2 mb-2">
                        <Skeleton size="lg" className="rounded-xl" />
                    </div>
                    <div className="border-b border-dark/10 pb-2 mb-2">
                        <Skeleton size="lg" className="rounded-xl" />
                    </div>
                    <div className="border-b border-dark/10 pb-2 mb-2">
                        <Skeleton size="lg" className="rounded-xl" />
                    </div>
                    <div className="border-b border-dark/10 pb-2 mb-2">
                        <Skeleton size="lg" className="rounded-xl" />
                    </div>
                    <Skeleton size="lg" className="rounded-xl" />
                </div>
            </ICCard>
        </div>
    );
};

const getNameForRank = (rank: number) => {
    switch (rank) {
        case 1:
            return 'first';
        case 2:
            return 'second';
        case 3:
            return 'third';
        default:
            return `${rank}th`;
    }
};

const Participant = ({ participant }: { participant: ICParticipant }) => {
    const { t } = useTranslation();
    const reward = getRewardForPosition(participant.rank);

    return (
        <section className="flex items-center group">
            <div className="flex flex-col items-start w-[35px]">
                {participant.rank < 4 && (
                    <img
                        src={`/inviteContest/${getNameForRank(participant.rank)}-place.png`}
                        alt=""
                        className="w-4 h-4"
                    />
                )}
                <span className="text-xs">#{participant.rank}</span>
            </div>

            <div className="flex items-center justify-between w-full py-2 border-b border-dark/10 group-last-of-type:border-0">
                <div className="flex items-center gap-2">
                    <img
                        src={getRandomAvatarForContest(participant.id)}
                        alt=""
                        className="w-6 h-6 rounded-full"
                    />
                    <div className="flex flex-col">
                        <p className="line-clamp-1 break-all">{participant.firstName}</p>
                        {participant.isWinner && (
                            <p className="text-2xs text-green">{t('invite-contest.winner')}</p>
                        )}
                    </div>
                </div>
                <div className="flex flex-col items-end gap-1 flex-[1_0_150px]">
                    <div className="flex items-center gap-1 whitespace-nowrap">
                        <img src="/tether.png" alt="" className="w-3 h-3 xs:w-4 xs:h-4" />
                        <span className="mr-1 xs:mr-2 text-sm xs:text-base">
                            {reward?.rewardUSDT}
                        </span>
                        <img src="/switch.png" alt="" className="w-3 h-3 xs:w-4 xs:h-4" />
                        <span className="text-sm xs:text-base">{reward?.rewardSWITCH}</span>
                    </div>
                    <p className="text-xs text-invite-contest-dark-blue">
                        $
                        {Intl.NumberFormat('en', {
                            maximumFractionDigits: 2,
                            notation: 'compact',
                        })
                            .format(participant.bigpumpSelfUSD)
                            .replace(',', '.')}
                        &nbsp;•&nbsp;
                        {Intl.NumberFormat('ru', {
                            maximumFractionDigits: 2,
                        })
                            .format(participant.points)
                            .replace(',', '.')}{' '}
                        pts
                    </p>
                </div>
            </div>
        </section>
    );
};
