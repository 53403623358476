import { useTranslation } from 'i18n';

type Props = {
    color?: string;
};

export const EmptyList = ({ color }: Props) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center p-10 gap-3">
            <svg
                width="39"
                height="60"
                viewBox="0 0 39 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.51957 57.2471L20.6935 40.6513L11.5283 31.2471L4.25 38.7152L20.6935 55.5875L35.25 40.6513L10.7196 15.4811L20.6935 5.24707"
                    stroke={color ?? '#5286D4'}
                    strokeWidth="5"
                />
                <path
                    d="M25.7461 0.25L11.8594 3.63702L22.3591 14.1368L25.7461 0.25Z"
                    fill={color ?? '#5286D4'}
                />
            </svg>
            <p
                className="text-main-blue text-xs"
                style={{
                    color,
                }}
            >
                {t('main.empty-token-list')}
            </p>
        </div>
    );
};
