import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Button } from 'common/components/button.tsx';
import { useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { WalletModal } from 'tonConnect/walletModal.tsx';
import { useState } from 'react';
import WebApp from '@twa-dev/sdk';
import { InviteContestBanner } from 'inviteContest/inviteContestBanner.tsx';
import { parseAddress } from 'common/utils/parseAddress.ts';
import { OpenTonConnectModal } from 'tonConnect/openTonConnectModal.tsx';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';
import { IS_TG } from 'common/constants';

export const Header = () => {
    const isConnected = useTonConnectConnectionStatus();
    const { isAuthorized } = useTonProof();
    const shouldBlurMainScreen = !IS_TG && !isAuthorized;

    const [isWalletOpen, setWalletOpen] = useState<boolean>(false);
    const openWallet = () => setWalletOpen(true);
    const closeWallet = () => setWalletOpen(false);

    return (
        <>
            <div className="w-full px-5 py-2.5 bg-blue flex items-center justify-between sticky top-0 z-50">
                <div className="flex items-center gap-2" onClick={navigation.mainPage}>
                    <img
                        src="/pump_logo.png"
                        alt="pump"
                        className="xs:w-9 xs:h-9 xs:min-w-[2.25rem] xs:block min-w-[1.75rem] w-[1.75rem] h-[1.75rem] hidden"
                    />
                    <div>
                        <TextWithShadow shadowWidth={1} className="italic text-sm xs:text-base">
                            BIG.PUMP
                        </TextWithShadow>
                        <div
                            className="bg-dark-red py-0.5 px-1 text-white flex items-center gap-0.5 text-3xs w-fit"
                            onClick={() =>
                                WebApp.openTelegramLink('https://t.me/pocketfi_bot/swap')
                            }
                        >
                            <span>by</span>
                            <img
                                src="/pocketfi_logo.svg"
                                alt="pocketfi"
                                className="w-3 h-3 min-w-3"
                            />
                            <span>PocketFi</span>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-1">
                    {/*<CabalArenaHeaderButton />*/}
                    {isConnected ? (
                        <Button
                            variant="blueGradient"
                            className="border-black !text-black px-4"
                            onClick={openWallet}
                        >
                            {squeezeAddress(parseAddress(tonConnectUI.account?.address), 4)}
                        </Button>
                    ) : (
                        <div className="flex items-center gap-2.5">
                            {shouldBlurMainScreen ? null : <OpenTonConnectModal />}
                        </div>
                    )}
                </div>
                <WalletModal isOpen={isWalletOpen} close={closeWallet} />
            </div>
            <InviteContestBanner />
        </>
    );
};
