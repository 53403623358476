import {
    CommunityNote,
    ReactionStates,
    updateReaction,
} from 'coin/communityNotes/communityNotes.store.ts';
import classNames from 'classnames';
import { Coin } from 'coin/coin.store';

export const LikesPanel = ({
    likeCount,
    dislikeCount,
    selfLike,
    selfDislike,
    noteId,
    coinId,
}: ReactionStates & { noteId: CommunityNote['id']; coinId: Coin['id'] }) => {
    const onLikeClick = () => {
        updateReaction({ noteId, reactionType: 'like', coinId });
    };

    const onDislikeClick = () => {
        updateReaction({ noteId, reactionType: 'dislike', coinId });
    };

    return (
        <div className="flex items-center">
            <button
                className="flex flex-col gap-1.5 items-center aspect-square w-9"
                onClick={onLikeClick}
            >
                <img src="/like.png" alt="" className="w-4" />
                <span
                    className={classNames('text-xs text-white leading-4 rounded-full px-1', {
                        [`bg-dark-green-v2`]: selfLike,
                    })}
                >
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        maximumFractionDigits: 0,
                    }).format(likeCount)}
                </span>
            </button>
            <button
                className="flex flex-col gap-1.5 items-center aspect-square w-9"
                onClick={onDislikeClick}
            >
                <img src="/dislike.png" alt="" className="w-4" />
                <span
                    className={classNames('text-xs text-white leading-4 rounded-full px-1', {
                        [`bg-light-red`]: selfDislike,
                    })}
                >
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        maximumFractionDigits: 0,
                    }).format(dislikeCount)}
                </span>
            </button>
        </div>
    );
};
