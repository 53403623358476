import { parseValue } from 'common/utils/parseValue.ts';
import { Coin } from 'coin/coin.store.ts';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { User } from 'user/user.store.ts';
import { t } from 'i18n';
import { Address } from '@ton/core';

export const displayNanoValue = (value: string | number | bigint, decimals = 9, precision = 2) => {
    return parseFloat(parseValue(value, decimals ?? 9)).toLocaleString('en-US', {
        maximumFractionDigits: precision,
    });
};

export const displayTonNanoValueInDollars = (value: string | number | bigint, price = 6) => {
    if (value === 'NaN') {
        return 0;
    }
    return parseFloat(parseValue(value, 9)) * price;
};

export const shareCoin = (user: User, coin: Coin) => {
    const url = generateReferralLink({
        user,
        params: {
            address: Address.parse(coin.address).toString(),
            coinId: coin.id,
        },
    });
    openShareWithFriendsLink(url, t('frens.invite.message'));
};

export const formatPrice = (price: string, tonPrice: number) => {
    const number = parseFloat(price) * tonPrice;
    if (number > 0.001) {
        return '$' + price;
    }

    function numZeroesAfterPoint(x: number) {
        const numStr = x.toExponential();

        const exponentMatch = numStr.match(/e-([0-9]+)/);
        const zerosCount = exponentMatch ? parseInt(exponentMatch[1]) - 1 : 0; // -1, чтобы учесть первую значимую цифру

        const significantPart = (x * Math.pow(10, zerosCount)).toFixed(6).slice(2);

        return `$0.0<sub>${zerosCount}</sub>${significantPart}`;
    }

    return numZeroesAfterPoint(number);
};

export const getCoinMarketCap = (coin: Coin, tonPrice: number): number => {
    const calculatedMarketCap = displayTonNanoValueInDollars(coin.marketCap, tonPrice);
    // if (
    //     coin.status === 'liquidity-sent' &&
    //     coin.liqUsdMarketCap &&
    //     parseFloat(coin.liqUsdMarketCap) > 0
    // ) {
    //     return parseFloat(coin.liqUsdMarketCap);
    // }

    return calculatedMarketCap;
};
