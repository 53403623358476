import { Card } from 'common/components/card.tsx';
import dayjs from 'dayjs';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { getCoinMarketCap } from 'coin/utils.ts';
import { TokenCreatedBy } from 'coin/components/tokenCreatedBy.tsx';
import { Progress } from 'common/components/progress.tsx';
import { useTon } from 'main/main.store.ts';
import { HoldersAmount } from 'coin/holders/holdersAmount.tsx';
import { NotesAmount } from 'coin/communityNotes/notesAmount.tsx';
import { PriceChange } from 'coin/components/priceChange.tsx';
import {
    getCoinsList,
    pullNewCoinsList,
    SortListType,
    useCoinsList,
} from 'main/coinsList.store.ts';
import { TickerLabel } from 'coin/components/tickerLabel.tsx';
import { AuthorWalletBalance } from 'coin/components/authorWalletBalance.tsx';
import { parseValue } from 'common/utils/parseValue.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { QuickBuyButton } from 'main/quickBuyButton.tsx';
import { Coin, CoinStatus } from 'coin/coin.store.ts';
import { useUser } from 'user/user.store.ts';
import { formatNewDuration } from 'main/utils.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import classNames from 'classnames';
import { openWindowBox } from 'desktop/desktop.store.ts';
import { WindowBoxKeys } from 'desktop/types.ts';

const isNew = (ts: string) => {
    return dayjs(ts).unix() * 1000 > Date.now() - 6 * 60 * 60 * 1000;
};

export const CoinsGrid = () => {
    const { coins, isFetching, isLoading, canFetchMore, search, searchResults, sortType } =
        useCoinsList();
    const { quickBuyEnabled } = useUser();

    useAutoFetch(pullNewCoinsList, 3000);

    const { price } = useTon();

    const anchorRef = useScrollToFetchData(() => getCoinsList(20), isLoading);

    const coinsToDisplay = search && searchResults.length ? searchResults : coins;

    const openCoinApp = (coin: Coin) => {
        openWindowBox({
            key: WindowBoxKeys.coin,
            title: coin.name,
            icon: coin.imageUrl,
            width: 900,
            height: (window.innerHeight * 3) / 4 - 100,
            minHeight: 430,
            minWidth: 900,
            contentProps: {
                coinId: coin.id,
            },
        });
    };

    return (
        <div className="grid gap-3 w-full coinsGrid relative">
            {isFetching && (
                <>
                    {[...Array(12)].map(() => (
                        <Skeleton size="lg" className="w-full h-[300px]" />
                    ))}
                </>
            )}
            {coinsToDisplay.map((coin) => (
                <Card
                    className="flex flex-col gap-2 !p-3"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        openCoinApp(coin);
                    }}
                    key={coin.id}
                >
                    <div className="relative w-full aspect-square">
                        <img
                            src={coin.imageUrl}
                            alt={coin.imageName}
                            className="w-full h-full min-w-full min-h-full aspect-square object-contain object-center"
                            onError={(e) => {
                                e.currentTarget.src = '/placeholder-img.png'; // Replace with your placeholder image path
                            }}
                        />
                        <span className="absolute left-0 top-0 bg-dark-green text-2xs text-white px-0.5 py-[1px]">
                            {isNew(coin.chainCreatedAt) ? `NEW ` : ''}
                            {formatNewDuration(
                                dayjs.duration(dayjs(coin.chainCreatedAt).diff(dayjs())).humanize()
                            )}
                        </span>
                        <TickerLabel className="absolute top-0 right-0">{coin.symbol}</TickerLabel>
                        <div className="absolute bottom-0 left-0 w-full bg-light-blue flex items-center justify-between gap-1 p-0.5">
                            <span className="text-sm font-semibold truncate text-white">
                                {coin.name}
                            </span>
                            <PriceChange
                                value={parseFloat(coin.priceChange1H)}
                                className="text-xs"
                            />
                        </div>
                    </div>
                    <p className="text-2xs line-clamp-3">{coin.description}</p>
                    <div className="mt-auto">
                        <TokenCreatedBy className="!text-light-gray">
                            {coin.user.username}
                        </TokenCreatedBy>
                    </div>
                    <div className="flex items-center gap-3">
                        <TokenMarketCap
                            className="!text-[#53A700]"
                            blockClassName="!bg-[#53A700] !text-white"
                            isNew
                            value={getCoinMarketCap(coin, price)}
                        />
                        <HoldersAmount value={parseFloat(coin.holderAmount)} />
                        <NotesAmount value={parseFloat(coin.noteAmount)} />
                        {sortType === SortListType.New && (
                            <AuthorWalletBalance value={parseValue(coin.authorBalanceInTON, 9)} />
                        )}
                    </div>
                    <Progress progress={parseFloat(coin.bondingCurveProgressTon)} />
                    {coin.status !== CoinStatus.LiquiditySent && quickBuyEnabled && (
                        <QuickBuyButton coin={coin} />
                    )}
                </Card>
            ))}
            <div
                className={classNames('absolute bottom-[1200px] left-0 w-full h-[1200px]', {
                    [`hidden`]: !canFetchMore,
                })}
                ref={anchorRef}
            />
            <>
                {[...Array(6)].map(() => (
                    <Skeleton
                        size="lg"
                        className={classNames('w-full h-[300px]', {
                            [`hidden`]: !canFetchMore,
                        })}
                    />
                ))}
            </>
        </div>
    );
};
