import { useEffect } from 'react';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';
import { useAppVisibleStatus } from 'app.store.ts';
import { useTgAuth } from 'desktop/tgAuth.store.ts';
import WebApp from '@twa-dev/sdk';

export const useAutoFetch = (callback: () => void, interval?: number, delay?: number) => {
    const { isAuthorized } = useTonProof();
    const savedInitData = useTgAuth();
    const isAppVisible = useAppVisibleStatus();
    useEffect(() => {
        if (!isAuthorized && !savedInitData && !WebApp.initData) {
            return;
        }
        if (!delay) {
            callback();
        } else {
            setTimeout(callback, delay);
        }

        if (interval && isAppVisible) {
            let intervalId: ReturnType<typeof setInterval>;
            let timeoutId: ReturnType<typeof setTimeout>;
            if (!delay) {
                intervalId = setInterval(callback, interval);
            } else {
                timeoutId = setTimeout(() => {
                    intervalId = setInterval(callback, interval);
                }, delay);
            }

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        }
    }, [isAuthorized, isAppVisible, savedInitData]);
};
