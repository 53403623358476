import { getRewardForPosition } from 'inviteContest/utils.ts';
import { useTranslation } from 'i18n';
import classNames from 'classnames';

type Props = {
    rank: number;
    randomProb: number;
    isWinner: boolean;
    isContestFinished: boolean;
};
export const EstimatedPrize = ({ rank, randomProb, isWinner, isContestFinished }: Props) => {
    const { t } = useTranslation();
    const prize = getRewardForPosition(rank);

    if (!prize) {
        return null;
    }

    const hideReward = isContestFinished && !isWinner;

    const text = isContestFinished
        ? isWinner
            ? t('invite-contest.you-win')
            : t('invite-contest.no-prize')
        : rank <= 100
          ? t('invite-contest.guaranteed-prize')
          : t('invite-contest.random-prize').replace(
                '{chance}',
                randomProb * 100 >= 0.01
                    ? Intl.NumberFormat('en', {
                          maximumFractionDigits: 2,
                      }).format(randomProb * 100)
                    : `<0.01`
            );

    return (
        <div className="font-Wix flex flex-col gap-0.5">
            <span
                className={classNames('text-2xs font-semibold', {
                    'text-green': isWinner && isContestFinished,
                    'text-invite-contest-dark-blue': !isContestFinished,
                    'text-red': !isWinner && isContestFinished,
                })}
            >
                {text}
            </span>
            <div
                className={classNames('flex items-center text-xs gap-2', {
                    hidden: hideReward,
                })}
            >
                <div className="flex gap-1 items-center">
                    <img src="/tether.png" alt="" className="w-3 h-3 -mt-0.5" />
                    <span>{prize.rewardUSDT}</span>
                </div>
                <div className="flex gap-1 items-center">
                    <img src="/switch.png" alt="" className="w-3 h-3 -mt-0.5" />
                    <span>{prize.rewardSWITCH}</span>
                </div>
            </div>
        </div>
    );
};
