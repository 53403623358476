import { Coin } from 'coin/coin.store.ts';
import { ChartComponent, ChartEvent } from 'common/components/chartComponents.tsx';
import { Button } from 'common/components/button.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { useCallback, useState } from 'react';
import { Range, Time } from 'lightweight-charts';
import debounce from 'lodash-es/debounce';
import { Skeleton } from 'common/components/skeleton.tsx';
import {
    ChartTimeframe,
    clearKlines,
    fetchKlines,
    fetchKlinesRange,
    pullCoinKlines,
    useCoinChart,
} from 'coin/chart/coinChart.store.ts';
import { IS_DESKTOP } from 'common/constants';

type ChartWithControlProps = {
    coin: Coin;
};

type Params = {
    fromDttm: Date;
    toDttm: Date;
    coinId: string;
    stepMinute: ChartTimeframe;
};

export const ChartWithControl = ({ coin }: ChartWithControlProps) => {
    const { serie, isFetched } = useCoinChart(coin.id);

    useAutoFetch(() => fetchKlinesRange(coin.id));
    const [params, setParams] = useState<Params>({
        fromDttm: new Date(Date.now() - 3_600_00),
        toDttm: new Date(),
        coinId: coin.id,
        stepMinute: 1,
    });

    const onParamChange = (changedParams: Partial<Params>) => {
        setParams((prev) => {
            const newParams = {
                ...prev,
                ...changedParams,
                coinId: coin.id,
            };
            if (isFetched) {
                fetchKlines({
                    fromDttm: newParams.fromDttm.getTime(),
                    toDttm: newParams.toDttm.getTime(),
                    coinId: coin.id,
                    stepMinute: newParams.stepMinute,
                });
            }
            return newParams;
        });
    };

    const onStepMinuteChange = useCallback(
        (stepMinute: ChartTimeframe) => {
            clearKlines(coin.id);
            onParamChange({ stepMinute });
        },
        [onParamChange]
    );

    const onVisibleRangeChange = useCallback(
        ({ from, to }: Range<Time>) => {
            const fromDttm = new Date((from as number) * 1000);
            const toDttm = new Date((to as number) * 1000);

            onParamChange({
                fromDttm,
                toDttm,
            });
        },
        [onParamChange]
    );

    const pullKlines = useCallback(() => {
        pullCoinKlines({ coinId: coin.id, stepMinute: params.stepMinute });
    }, [params, coin, isFetched]);

    useAutoFetch(pullKlines, 5000, 5000);

    const debounceOnVisibleRangeChange = useCallback(debounce(onVisibleRangeChange, 500), [
        onVisibleRangeChange,
    ]);

    const events: ChartEvent[] = [];

    if (coin.liqSentAt) {
        events.push({
            time: (new Date(coin.liqSentAt).getTime() / 1000) as Time,
            color: '#0446c0',
            shape: 'circle',
            position: 'aboveBar',
            text: 'DEX',
        });
    }

    return (
        <div className="flex flex-col gap-2 w-full">
            {serie.length ? (
                <ChartComponent
                    data={serie}
                    events={events}
                    onVisibleRangeChange={debounceOnVisibleRangeChange}
                    type="area"
                />
            ) : (
                <Skeleton height={IS_DESKTOP ? 584 : 300} />
            )}
            <div className="grid grid-cols-3 gap-1.5 w-full">
                <Button
                    variant="blueGradient"
                    className="border-black h-8"
                    onClick={() => onStepMinuteChange(1)}
                >
                    1 min
                </Button>
                <Button
                    variant="blueGradient"
                    className="border-black h-8"
                    onClick={() => onStepMinuteChange(5)}
                >
                    5 min
                </Button>
                <Button
                    variant="blueGradient"
                    className="border-black h-8"
                    onClick={() => onStepMinuteChange(15)}
                >
                    15 min
                </Button>
            </div>
        </div>
    );
};
