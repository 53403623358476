import { KingOfHill } from 'main/kingOfHill.tsx';
import { WindowBox, WindowBoxKeys } from 'desktop/types.ts';
import { FC } from 'react';
import { Coins } from 'desktop/windows/coins.tsx';
import { CreateCoinPage } from 'createToken/createCoin.page.tsx';
import { FrensWindow } from 'desktop/windows/frens.tsx';
import { CoinWindow } from 'desktop/windows/coin.tsx';
import { MineSweeper } from 'desktop/windows/minesweeper';

export const MINIMIZED_WINDOW_HEIGHT = 35;

export const mapWindowKeyToContent: Record<WindowBoxKeys, FC<any>> = {
    kingOfTheHill: KingOfHill,
    coinsList: Coins,
    coin: CoinWindow,
    createCoin: CreateCoinPage,
    frens: FrensWindow,
    minesweeper: MineSweeper,
};

export const TOKEN_LIST_APP: WindowBox = {
    key: WindowBoxKeys.coinsList,
    title: 'Token List',
    icon: '/desktop/apps/token_list.gif',
    width: 700,
    height: (window.innerHeight / 4) * 3,
    minWidth: 400,
};

export const KING_OF_HILL_APP: WindowBox = {
    key: WindowBoxKeys.kingOfTheHill,
    title: 'King of the hill',
    icon: '/desktop/apps/king_of_the_hill.gif',
    width: 450,
    height: 220,
    minHeight: 220,
    minWidth: 280,
    maxHeight: 220,
};

export const CREATE_COIN_APP: WindowBox = {
    key: WindowBoxKeys.createCoin,
    title: 'Create Coin',
    icon: '/gem.gif',
    width: 400,
    height: Math.min(window.innerHeight - 100, 800),
    minWidth: 400,
    minHeight: Math.min(window.innerHeight - 100, 500),
    y: 100,
    contentProps: {
        fullHeight: true,
    },
};

export const FRIENDS_APP: WindowBox = {
    key: WindowBoxKeys.frens,
    title: 'Frens & Rewards',
    icon: '/frens-cat.png',
    width: 600,
    height: 600,
    minWidth: 500,
    minHeight: 500,
};

export const MINESWEEPER: WindowBox = {
    key: WindowBoxKeys.minesweeper,
    title: 'Minesweeper',
    icon: '/desktop/apps/minesweeper.png',
    width: 400,
    height: 400,
    contentProps: {
        _noScroll: true,
    },
    noResize: true,
};

export const APPS: WindowBox[] = [
    TOKEN_LIST_APP,
    KING_OF_HILL_APP,
    CREATE_COIN_APP,
    FRIENDS_APP,
    MINESWEEPER,
];
