import { WindowBoxWithState, WindowState } from 'desktop/types.ts';
import classNames from 'classnames';
import {
    closeWindowBox,
    focusWindowBox,
    minimizeWindowBox,
    moveWindowBox,
    resizeWindowBox,
} from 'desktop/desktop.store.ts';
import WinBox from 'react-winbox';
import { useEffect, useRef } from 'react';
import { MINIMIZED_WINDOW_HEIGHT } from 'desktop/constants.tsx';

type Props = {
    window: WindowBoxWithState;
};

export const WrapperWinBox = ({ window }: Props) => {
    const ref = useRef<WinBox>(null);

    useEffect(() => {
        if (ref.current) {
            if (window.state === WindowState.Minimized) {
                ref.current.minimize();
            }

            if (window.state === WindowState.Focused) {
                ref.current.focus();
            }
        }
    }, [window]);

    const Content = window.content;

    return (
        <WinBox
            ref={ref}
            id={window.uuid}
            title={window.title}
            icon={window.icon}
            className={classNames('absolute', {
                'mt-10': window.state !== WindowState.Minimized,
                '!top-0 !shadow-none': window.state === WindowState.Minimized,
                'no-wb-scroll': window.contentProps?._noScroll,
            })}
            onClose={() => closeWindowBox(window.uuid)}
            min={window.state === WindowState.Minimized}
            onMinimize={() => minimizeWindowBox(window.uuid)}
            onFocus={() => focusWindowBox(window.uuid)}
            onMove={(x, y) => moveWindowBox(window.uuid, x, y)}
            onResize={(width, height) => resizeWindowBox(window.uuid, width, height)}
            x={window.x}
            y={window.y}
            width={window.width}
            height={window.height}
            minWidth={
                window.state === WindowState.Minimized ? MINIMIZED_WINDOW_HEIGHT : window.minWidth
            }
            minHeight={
                window.state === WindowState.Minimized ? MINIMIZED_WINDOW_HEIGHT : window.minHeight
            }
            maxWidth={window.maxWidth}
            maxHeight={window.maxHeight}
            noResize={window.noResize}
        >
            <Content {...(window.contentProps ?? {})} />
        </WinBox>
    );
};
