import WebApp from '@twa-dev/sdk';

export const openExternalLink = (url: string) => {
    if (WebApp.platform !== 'macos') {
        WebApp.openLink(url);
        return;
    }
    window.open(url, '_blank');
};

export const openExternalLinkInBrowser = (url: string) => {
    window.open(url, '_blank');
};
