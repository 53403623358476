import { FC, ReactNode } from 'react';

type Props = {
    open: boolean;
    closable: boolean;
    onClose: () => void;
    children: ReactNode;
    title?: string;
};

export const DesktopModal: FC<Props> = ({ open, closable, onClose, children, title = '' }) => {
    if (!open) {
        return null;
    }

    return (
        <div
            className={`fixed top-0 left-0 z-50 bg-[#9C9C9C66] backdrop-blur transition-opacity h-screen w-screen p-5 ${
                open ? 'opacity-100 cursor-pointer' : 'opacity-0 pointer-events-none'
            }`}
            onClick={closable ? onClose : undefined}
        >
            <div
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-1 border-[#0019CF] rounded-t-md z-50 cursor-auto w-fit overflow-hidden`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="wb-header flex justify-between items-center !relative">
                    <p className="wb-title">{title}</p>
                    <button onClick={onClose}>
                        <img src="/desktop/close.png" alt="close" />
                    </button>
                </div>
                <div className="bg-[#ece9d8] p-4">{children}</div>
            </div>
        </div>
    );
};
