import React, { JSX, useEffect, useRef, useState } from 'react';
import './Minesweeper.css';
import { Difficulty } from 'desktop/windows/minesweeper/index.tsx';
import { resizeWindowBox } from 'desktop/desktop.store.ts';

// Пути к изображениям (из папки public/minesweeper)
const dead = '/minesweeper/dead.png';
const smile = '/minesweeper/smile.png';
const win = '/minesweeper/win.png';
const ohh = '/minesweeper/ohh.png';
const empty = '/minesweeper/empty.png';
const open1 = '/minesweeper/open1.png';
const open2 = '/minesweeper/open2.png';
const open3 = '/minesweeper/open3.png';
const open4 = '/minesweeper/open4.png';
const open5 = '/minesweeper/open5.png';
const open6 = '/minesweeper/open6.png';
const open7 = '/minesweeper/open7.png';
const open8 = '/minesweeper/open8.png';
const flag = '/minesweeper/flag.png';
const mine = '/minesweeper/mine-ceil.png';
const mineDeath = '/minesweeper/mine-death.png';
const misFlagged = '/minesweeper/misflagged.png';
const question = '/minesweeper/question.png';
const checked = '/minesweeper/checked.png';
const digit0 = '/minesweeper/digit0.png';
const digit1 = '/minesweeper/digit1.png';
const digit2 = '/minesweeper/digit2.png';
const digit3 = '/minesweeper/digit3.png';
const digit4 = '/minesweeper/digit4.png';
const digit5 = '/minesweeper/digit5.png';
const digit6 = '/minesweeper/digit6.png';
const digit7 = '/minesweeper/digit7.png';
const digit8 = '/minesweeper/digit8.png';
const digit9 = '/minesweeper/digit9.png';
const digit_ = '/minesweeper/digit-.png';

const digits = [digit0, digit1, digit2, digit3, digit4, digit5, digit6, digit7, digit8, digit9];

export interface Ceil {
    state: 'open' | 'flag' | 'misflagged' | 'mine' | 'die' | 'unknown' | string;
    minesAround?: number;
    opening?: boolean;
}

interface MineSweeperViewProps {
    ceils: Ceil[];
    className?: string;
    changeCeilState: (index: number) => void;
    onReset: (difficulty?: Difficulty) => void;
    openCeil: (index: number) => void;
    openCeils: (index: number) => void;
    mines: number;
    status: 'died' | 'won' | string;
    seconds: number;
    onClose: () => void;
    difficulty: string;
    openingCeil: (index: number) => void;
    openingCeils: (index: number) => void;
    sameTouchPos: boolean;
    lastTouch: number;
    columns: number;
    rows: number;
    uuid: string;
}

interface OpenBehavior {
    index: number;
    behavior: 'single' | 'multi' | '';
}

function renderDigits(number: number): JSX.Element | JSX.Element[] {
    let numberStr: string;
    if (number < 0) {
        const _number = -number % 100;
        if (_number === 0) {
            numberStr = '00';
        } else if (_number < 10) {
            numberStr = '0' + _number;
        } else {
            numberStr = String(_number);
        }
        return (
            <>
                <img src={digit_} alt="-" />
                {numberStr.split('').map((n, i) => (
                    <img src={digits[parseInt(n)]} key={i} alt={n} />
                ))}
            </>
        );
    }

    numberStr = number < 999 ? String(number) : '999';
    if (number < 10) numberStr = '00' + numberStr;
    else if (number < 100) numberStr = '0' + numberStr;
    return numberStr.split('').map((n, i) => <img key={i} src={digits[parseInt(n)]} alt={n} />);
}

export const MineSweeperView: React.FC<MineSweeperViewProps> = (props) => {
    const {
        ceils,
        className,
        changeCeilState,
        onReset,
        openCeil,
        openCeils,
        mines,
        status,
        seconds,
        onClose,
        difficulty,
        openingCeil,
        openingCeils,
        sameTouchPos,
        lastTouch,
        columns,
        rows,
        uuid,
    } = props;

    const face = useRef<HTMLButtonElement>(null);
    const dropDown = useRef<HTMLDivElement>(null);
    const topBar = useRef<HTMLDivElement>(null);
    const [mouseDownContent, setMouseDownContent] = useState<boolean>(false);
    const [openOption, setOpenOption] = useState<string | null>(null);
    const [openBehavior, setOpenBehavior] = useState<OpenBehavior>({ index: -1, behavior: '' });
    const containerRef = useRef<HTMLDivElement>(null);

    function remainMines(): number {
        return (
            mines -
            ceils.filter((ceil) => ceil.state === 'flag' || ceil.state === 'misflagged').length
        );
    }

    function statusFace(): JSX.Element {
        if (mouseDownContent) return <img alt="ohh" src={ohh} />;
        switch (status) {
            case 'died':
                return <img alt="dead" src={dead} />;
            case 'won':
                return <img alt="win" src={win} />;
            default:
                return <img alt="smile" src={smile} />;
        }
    }

    function onMouseDownContent(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        if (e.button !== 0) return;
        if (
            (face.current && face.current.contains(e.target as Node)) ||
            status === 'won' ||
            status === 'died'
        )
            return;
        setMouseDownContent(true);
    }

    useEffect(() => {
        const { index, behavior } = openBehavior;
        if (behavior === 'single') {
            openingCeil(index);
        } else if (behavior === 'multi') {
            openingCeils(index);
        } else {
            openCeil(-1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBehavior.index, openBehavior.behavior]);

    function onMouseDownCeils(
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ): void {
        if (e.button === 2 && e.buttons === 2 && index !== -1) {
            changeCeilState(index);
        } else if (e.button === 0 && e.buttons === 1) {
            setOpenBehavior({
                index,
                behavior: 'single',
            });
        } else if (e.buttons === 3) {
            setOpenBehavior({
                index,
                behavior: 'multi',
            });
        }
    }

    function onMouseOverCeils(index: number): void {
        setOpenBehavior({
            index,
            behavior: openBehavior.behavior,
        });
    }

    function onMouseUpCeils(): void {
        const { behavior, index } = openBehavior;
        if (index === -1) return;
        if (behavior === 'single') {
            openCeil(index);
        } else if (behavior === 'multi') {
            openCeils(index);
        }
    }

    function hoverOption(option: 'Game' | 'Help'): void {
        if (openOption) setOpenOption(option);
    }

    function onMouseUp(e: MouseEvent): void {
        setOpenBehavior({ index: -1, behavior: '' });
        setMouseDownContent(false);
        if (dropDown.current && !dropDown.current.contains(e.target as Node)) {
            setOpenOption('');
        }
    }

    function onTouchEndDropdown(e: TouchEvent): void {
        if (
            dropDown.current &&
            topBar.current &&
            !dropDown.current.contains(e.target as Node) &&
            !topBar.current.contains(e.target as Node)
        ) {
            setOpenOption('');
        }
    }

    function onTouchEndCeils(e: React.TouchEvent<HTMLDivElement>): void {
        const target = e.target as Element;
        const closest = target.closest('.mine__ceil');
        if (!closest) return;
        const index = Array.prototype.indexOf.call(e.currentTarget.children, closest);
        if (index === -1 || !sameTouchPos) return;
        if (new Date().getTime() - lastTouch < 150) {
            if (ceils[index].state === 'open') {
                openCeils(index);
            } else {
                openCeil(index);
            }
        } else {
            changeCeilState(index);
        }
    }

    useEffect(() => {
        window.addEventListener('touchend', onTouchEndDropdown);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mouseup', onMouseUp);
            window.removeEventListener('touchend', onTouchEndDropdown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const resizeObserver = new ResizeObserver(([cont]) => {
                if (cont.contentRect.width > 10 && cont.contentRect.height > 10) {
                    resizeWindowBox(uuid, cont.contentRect.width + 2, cont.contentRect.height + 38);
                }
            });

            resizeObserver.observe(container);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    return (
        <div
            className={`minesweeper inline-block ${className || ''}`}
            onContextMenu={(e) => e.preventDefault()}
            ref={containerRef}
        >
            {/* Выпадающие меню */}
            <div className="mine__drop-downs" ref={dropDown}>
                <div
                    style={{ visibility: openOption === 'Game' ? 'visible' : 'hidden' }}
                    className="mine__drop-down"
                >
                    <div className="mine__drop-down__title">Game</div>
                    <div className="mine__drop-down__menu">
                        <div className="mine__drop-down__row" onMouseUp={() => onReset()}>
                            <div className="mine__drop-down__check" />
                            <div className="mine__drop-down__text">New</div>
                            <span className="mine__drop-down__hot-key">F2</span>
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__separator" />
                        <div
                            className="mine__drop-down__row"
                            onMouseUp={() => onReset('Beginner')}
                            onTouchStart={() => onReset('Beginner')}
                        >
                            <div className="mine__drop-down__check">
                                {difficulty === 'Beginner' && <img src={checked} alt="checked" />}
                            </div>
                            <span>Beginner</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div
                            className="mine__drop-down__row"
                            onMouseUp={() => onReset('Intermediate')}
                            onTouchStart={() => onReset('Intermediate')}
                        >
                            <div className="mine__drop-down__check">
                                {difficulty === 'Intermediate' && (
                                    <img src={checked} alt="checked" />
                                )}
                            </div>
                            <span>Intermediate</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div
                            className="mine__drop-down__row"
                            onMouseUp={() => onReset('Expert')}
                            onTouchStart={() => onReset('Expert')}
                        >
                            <div className="mine__drop-down__check">
                                {difficulty === 'Expert' && <img src={checked} alt="checked" />}
                            </div>
                            <span>Expert</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <span>Custom...</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__separator" />
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check">
                                <img src={checked} alt="checked" />
                            </div>
                            <span>Marks (?)</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check">
                                <img src={checked} alt="checked" />
                            </div>
                            <span>Color</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <span>Sound</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__separator" />
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <span>Best Times...</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__separator" />
                        <div className="mine__drop-down__row" onMouseUp={onClose}>
                            <div className="mine__drop-down__check" />
                            <span>Exit</span>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                    </div>
                </div>
                <div
                    style={{ visibility: openOption === 'Help' ? 'visible' : 'hidden' }}
                    className="mine__drop-down"
                >
                    <div className="mine__drop-down__title">Help</div>
                    <div className="mine__drop-down__menu">
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <div className="mine__drop-down__text">Contents</div>
                            <span className="mine__drop-down__hot-key">F1</span>
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <div className="mine__drop-down__text">Search for Help on...</div>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <div className="mine__drop-down__text">Using Help</div>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__separator" />
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <div className="mine__drop-down__text">About Minesweeper...</div>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                        <div className="mine__drop-down__row">
                            <div className="mine__drop-down__check" />
                            <a
                                className="mine__drop-down__text"
                                href="https://github.com/ShizukuIchi/minesweeper"
                            >
                                Github
                            </a>
                            <span className="mine__drop-down__hot-key" />
                            <div className="mine__drop-down__arrow" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Верхняя панель */}
            <div className="mine__top-bar" ref={topBar}>
                <div
                    onMouseDown={() => setOpenOption('Game')}
                    onTouchStart={() => setOpenOption(openOption ? '' : 'Game')}
                    onMouseOver={() => hoverOption('Game')}
                    className="mine__top-bar__text hover:bg-[#0b61ff] hover:text-white"
                >
                    Game
                </div>
                <div
                    onMouseDown={() => setOpenOption('Help')}
                    onTouchStart={() => setOpenOption(openOption ? '' : 'Help')}
                    onMouseOver={() => hoverOption('Help')}
                    className="mine__top-bar__text hover:bg-[#0b61ff] hover:text-white"
                >
                    Help
                </div>
            </div>
            {/* Контент игры */}
            <section className="mine__content" onMouseDown={onMouseDownContent}>
                <div className="mine__score-bar">
                    <div className="mine__digits__outer">{renderDigits(remainMines())}</div>
                    <div className="mine__face__outer">
                        <button ref={face} className="mine__face" onClick={() => onReset()}>
                            {statusFace()}
                            <img alt="smile" src={smile} />
                        </button>
                    </div>
                    <div className="mine__digits__outer">{renderDigits(seconds)}</div>
                </div>
                <div
                    className="mine__content__inner"
                    style={{
                        gridTemplateColumns: `repeat(${columns}, 16px)`,
                        gridTemplateRows: `repeat(${rows}, 16px)`,
                    }}
                    onTouchEnd={onTouchEndCeils}
                    onMouseUp={onMouseUpCeils}
                >
                    <Ceils
                        ceils={ceils}
                        onMouseDown={onMouseDownCeils}
                        onMouseEnter={onMouseOverCeils}
                    />
                </div>
            </section>
        </div>
    );
};

function getTextImg(index: number): string {
    const imgs = [empty, open1, open2, open3, open4, open5, open6, open7, open8];
    return imgs[index];
}

interface CeilsProps {
    ceils: Ceil[];
    onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
    onMouseEnter: (index: number) => void;
}

const Ceils: React.FC<CeilsProps> = ({ ceils, onMouseDown, onMouseEnter }) => {
    function renderContent(ceil: Ceil): JSX.Element {
        const { state, minesAround, opening } = ceil;
        switch (state) {
            case 'open':
                return <MinesAround mines={minesAround ?? 0} />;
            case 'flag':
                return <Flag />;
            case 'misflagged':
                return <MisFlagged />;
            case 'mine':
                return <Mine />;
            case 'die':
                return <Die />;
            case 'unknown':
                return opening ? <QuestionOpen /> : <Question />;
            default:
                return opening ? <CeilBackgroundOpen /> : <CeilBackgroundCover />;
        }
    }

    return (
        <>
            {ceils.map((ceil, index) => (
                <div
                    key={index}
                    onMouseEnter={() => onMouseEnter(index)}
                    onMouseDown={(e) => onMouseDown(e, index)}
                    className="mine__ceil"
                >
                    {renderContent(ceil)}
                </div>
            ))}
        </>
    );
};

const Die: React.FC = () => (
    <>
        <CeilBackgroundOpen />
        <img alt="death" src={mineDeath} />
    </>
);

const MisFlagged: React.FC = () => (
    <>
        <CeilBackgroundOpen />
        <img alt="misFlagged" src={misFlagged} />
    </>
);

const Flag: React.FC = () => (
    <>
        <CeilBackgroundCover />
        <img alt="flag" src={flag} />
    </>
);

interface MinesAroundProps {
    mines: number;
}

const MinesAround: React.FC<MinesAroundProps> = ({ mines }) => (
    <>
        <CeilBackgroundOpen />
        <img alt="mines-around" src={getTextImg(mines)} />
    </>
);

const Question: React.FC = () => (
    <>
        <CeilBackgroundCover />
        <img alt="question" src={question} />
    </>
);

const QuestionOpen: React.FC = () => (
    <>
        <CeilBackgroundOpen />
        <img alt="question" src={question} />
    </>
);

const Mine: React.FC = () => (
    <>
        <CeilBackgroundOpen />
        <img alt="mine" src={mine} />
    </>
);

const CeilBackgroundCover: React.FC = () => {
    return <div className="ceil-background-cover"></div>;
};

const CeilBackgroundOpen: React.FC = () => {
    return <div className="ceil-background-open"></div>;
};
