import { FC, PropsWithChildren } from 'react';

export enum WindowBoxKeys {
    kingOfTheHill = 'kingOfTheHill',
    coinsList = 'coinsList',
    coin = 'coin',
    createCoin = 'createCoin',
    frens = 'frens',
    minesweeper = 'minesweeper',
}

export enum WindowState {
    Opened = 'opened',
    Focused = 'focused',
    Minimized = 'minimized',
}

export type WindowBox = {
    key: WindowBoxKeys;
    title: string;
    icon: string;
    width: number;
    height: number;
    x?: number;
    y?: number;
    minHeight?: number;
    minWidth?: number;
    maxHeight?: number;
    maxWidth?: number;
    contentProps?: Record<string, any>;
    noResize?: boolean;
};

export type WindowBoxState = 'minimized' | 'opened' | 'focused';

export type WindowBoxWithState = WindowBox & {
    content: FC;
    state: WindowBoxState;
    x: number;
    y: number;
    lastResize: {
        width: number;
        height: number;
    };
    lastPosition: {
        x: number;
        y: number;
    };
    uuid: string;
};
