import Bugsnag from '@bugsnag/js';
import './posthog.ts';
import './index.css';
import registerServiceWorker from 'registerServiceWorker.ts';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { sendReferralCode } from 'frens/frens.store.ts';
import './bugsnag.ts';
import { gtag } from 'gtag.ts';
import WebApp from '@twa-dev/sdk';
import { releaseStage } from 'common/constants';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { RouterProvider } from 'react-router';
import { router } from 'common/utils/router.tsx';
import { IS_TG } from 'common/constants';
import { expandWebApp } from 'common/utils/tgUtilt.ts';

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

declare global {
    interface Window {
        tonConnectUI: import('@tonconnect/ui').TonConnectUI;
        tonkeeper: {
            tonconnect: any;
        };
    }
}

expandWebApp();
registerServiceWorker();
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

window.addEventListener('scroll', () => {
    window.scrollTo(0, 0);
});

async function main() {
    const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

    if (startParams?.referralCode) {
        await sendReferralCode(startParams.referralCode);
    }

    gtag('setTelegramID', {});

    if (releaseStage === 'development') {
        window.tonkeeper = {
            tonconnect: {},
        };
    }

    if (IS_TG) {
        WebApp.lockOrientation();
    }

    const root = createRoot(document.getElementById('app')!);

    root.render(
        <ErrorBoundary>
            <RouterProvider router={router} />
        </ErrorBoundary>
    );
}

main();
