import { WindowBoxWithState, WindowState } from 'desktop/types.ts';
import { closeWindowBox, toggleWindowBox, useDesktopStore } from 'desktop/desktop.store.ts';
import { useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import {
    FC,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { parseAddress } from 'common/utils/parseAddress.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { Button } from 'common/components/button.tsx';
import { OpenTonConnectModal } from 'tonConnect/openTonConnectModal.tsx';
import { changeAnonSetting, fetchTonBalance, useUser } from 'user/user.store.ts';
import { fetchTonPrice, useTon } from 'main/main.store.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { useTranslation } from 'i18n';
import { Card } from 'common/components/card.tsx';
import { capitalize } from 'lodash-es';
import { displayNanoValue } from 'coin/utils.ts';
import { CopyButton } from 'common/components/copyButton.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { StartMenu } from 'desktop/components/startMenu.tsx';

export const CommandBar = () => {
    const { apps } = useDesktopStore();
    const commandBarRef = useRef<HTMLDivElement>(null);
    const startMenuRef = useRef<HTMLDivElement>(null);
    const connectionStateRef = useRef<HTMLDivElement>(null);

    const [overflowApps, setOverflowApps] = useState<WindowBoxWithState[]>([]);
    const [showOverflow, setShowOverflow] = useState(false);
    const appsContainerRef = useRef<HTMLDivElement>(null);

    const [appsBarWidth, setAppsBarWidth] = useState<number | null>(null);
    const calculateFreeSpaceForApps = useCallback(() => {
        if (!commandBarRef.current || !startMenuRef.current || !connectionStateRef.current)
            return null;
        const commandBarWidth = commandBarRef.current.clientWidth;
        const startMenuWidth = startMenuRef.current.clientWidth;
        const connectionWidth = connectionStateRef.current.clientWidth;

        return commandBarWidth - startMenuWidth - connectionWidth - 16;
    }, []);

    const updateOverflow = () => {
        if (!appsContainerRef.current) return;

        const container = appsContainerRef.current;
        const containerWidth = container.clientWidth;
        let currentWidth = 0;
        const visibleApps: WindowBoxWithState[] = [];
        const hiddenApps: WindowBoxWithState[] = [];

        apps.forEach((app) => {
            currentWidth += 156; // 140px width + padding + borders
            if (currentWidth <= containerWidth) {
                visibleApps.push(app);
            } else {
                hiddenApps.push(app);
            }
        });

        setOverflowApps(hiddenApps);
    };

    useEffect(() => {
        setAppsBarWidth(calculateFreeSpaceForApps);
    }, [apps]);

    useEffect(() => {
        updateOverflow();
        window.addEventListener('resize', updateOverflow);
        return () => window.removeEventListener('resize', updateOverflow);
    }, [apps, appsBarWidth]);

    return (
        <div
            ref={commandBarRef}
            className="w-screen max-w-screen h-10 flex items-center gap-2 z-[1000] controlPanel"
        >
            <div ref={startMenuRef}>
                <StartMenu />
            </div>
            <div
                ref={appsContainerRef}
                className="flex items-center gap-1"
                style={{
                    width: appsBarWidth ?? '100%',
                }}
            >
                {apps.slice(0, apps.length - overflowApps.length).map((app: WindowBoxWithState) => (
                    <button
                        key={app.uuid}
                        className="flex items-center rounded border-[#062A73] border px-2.5 py-2 gap-1.5 w-[140px] box-content"
                        style={{
                            background:
                                app.state === WindowState.Focused
                                    ? 'linear-gradient(180deg, #003EA2 0%, #004BAC 25.49%, #0052C0 76.73%, #0051BF 100%)'
                                    : 'linear-gradient(180deg, #53ABFF 0%, #1484FC 15.51%, #0582FC 70.41%, #077DFF 83.91%, #0A86FF 100%)',
                        }}
                        onClick={() => toggleWindowBox(app.uuid)}
                    >
                        <img src={app.icon} alt="" className="w-4 h-4" />
                        <span className="text-white text-xs leading-none font-normal line-clamp-1">
                            {app.title}
                        </span>
                        <button onClick={() => closeWindowBox(app.uuid)} className="ml-auto">
                            <img src="/desktop/close.png" alt="x" className="w-4 -mt-0.5 min-w-4" />
                        </button>
                    </button>
                ))}

                {overflowApps.length > 0 && (
                    <div className="relative">
                        <button
                            className="flex justify-center items-center h-full rounded border-[#062A73] border px-2 py-2"
                            style={{
                                background: showOverflow
                                    ? 'linear-gradient(180deg, #003EA2 0%, #004BAC 25.49%, #0052C0 76.73%, #0051BF 100%)'
                                    : 'linear-gradient(180deg, #53ABFF 0%, #1484FC 15.51%, #0582FC 70.41%, #077DFF 83.91%, #0A86FF 100%)',
                            }}
                            onClick={() => setShowOverflow(!showOverflow)}
                        >
                            <svg
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={classNames('w-2', {
                                    'rotate-90': showOverflow,
                                    '-rotate-90': !showOverflow,
                                })}
                            >
                                <path
                                    d="M2.93751 8.0013L9.06251 14.1263C9.27084 14.3346 9.37154 14.5812 9.36459 14.8659C9.35765 15.1506 9.25001 15.3971 9.04168 15.6055C8.83334 15.8138 8.58682 15.918 8.30209 15.918C8.01737 15.918 7.77084 15.8138 7.56251 15.6055L1.16668 9.1888C1.00001 9.02214 0.87501 8.83463 0.791677 8.6263C0.708343 8.41797 0.666677 8.20963 0.666677 8.0013C0.666677 7.79297 0.708343 7.58463 0.791677 7.3763C0.87501 7.16797 1.00001 6.98047 1.16668 6.8138L7.58334 0.397136C7.79168 0.188803 8.03473 0.088107 8.31251 0.0950517C8.59029 0.101996 8.83334 0.209636 9.04168 0.417969C9.25001 0.626302 9.35418 0.87283 9.35418 1.15755C9.35418 1.44228 9.25001 1.6888 9.04168 1.89713L2.93751 8.0013Z"
                                    fill="#d2d2d2"
                                />
                            </svg>
                        </button>

                        <div
                            className={classNames(
                                'absolute top-full -right-0.5 mt-1 rounded-lg shadow-lg controlPanel border border-gray-200 z-50 flex flex-col gap-0.5',
                                {
                                    hidden: !showOverflow,
                                }
                            )}
                        >
                            {overflowApps.map((app) => (
                                <button
                                    key={app.uuid}
                                    className="flex items-center rounded border-[#062A73] border px-2.5 py-2 gap-1.5 w-[140px] box-content"
                                    style={{
                                        background:
                                            app.state === WindowState.Focused
                                                ? 'linear-gradient(180deg, #003EA2 0%, #004BAC 25.49%, #0052C0 76.73%, #0051BF 100%)'
                                                : 'linear-gradient(180deg, #53ABFF 0%, #1484FC 15.51%, #0582FC 70.41%, #077DFF 83.91%, #0A86FF 100%)',
                                    }}
                                    onClick={() => toggleWindowBox(app.uuid)}
                                >
                                    <img src={app.icon} alt="" className="w-4 h-4" />
                                    <span className="text-white text-xs leading-none font-normal line-clamp-1">
                                        {app.title}
                                    </span>
                                    <button
                                        onClick={() => closeWindowBox(app.uuid)}
                                        className="ml-auto"
                                    >
                                        <img
                                            src="/desktop/close.png"
                                            alt="x"
                                            className="w-4 -mt-0.5 min-w-4"
                                        />
                                    </button>
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="ml-auto" ref={connectionStateRef}>
                <ConnectionState />
            </div>
        </div>
    );
};

const ConnectionState = () => {
    const isConnected = useTonConnectConnectionStatus();
    const { tonBalance } = useUser();
    const { price } = useTon();

    useAutoFetch(fetchTonBalance);
    useAutoFetch(fetchTonPrice);

    const [isWalletOpen, setWalletOpen] = useState<boolean>(false);
    const toggleWallet: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setWalletOpen((prev) => !prev);
    };
    const closeWallet = () => setWalletOpen(false);

    if (isConnected) {
        return (
            <WalletPopover isOpen={isWalletOpen} close={closeWallet}>
                <button
                    onClick={toggleWallet}
                    className="flex items-center gap-1 px-3 py-2.5 border-l border-[#002f73] commandBar__walletContainer"
                >
                    <img src="/desktop/wallet.png" alt="wallet" className="w-4 h-4" />
                    <span className="text-white text-xs font-normal whitespace-nowrap">
                        {squeezeAddress(parseAddress(tonConnectUI.account?.address), 4)}
                    </span>
                    <span className="ml-1 text-2xs font-normal">
                        $
                        {new BigNumber(parseValue(tonBalance ?? 0, 9))
                            .multipliedBy(price)
                            .toFixed(2)}
                    </span>
                </button>
            </WalletPopover>
        );
    }

    return (
        <div className="flex items-center gap-2">
            <OpenTonConnectModal />
        </div>
    );
};

type WalletPopoverProps = {
    isOpen: boolean;
    close: () => void;
};

const WalletPopover: FC<PropsWithChildren<WalletPopoverProps>> = ({ isOpen, close, children }) => {
    const { t } = useTranslation();
    const { tonBalance, user } = useUser();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!tonConnectUI.account) {
            close();
        }
    }, [tonConnectUI.account]);

    const onDisconnect = async () => {
        await tonConnectUI.disconnect();
        close();
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                isOpen &&
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                close();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [isOpen, close]);

    return (
        <div ref={containerRef} className="relative">
            {children}
            <div
                className={classNames('absolute top-[calc(100%+0.5rem)] right-1', {
                    hidden: !isOpen,
                })}
            >
                <div className="ml-auto mr-6 w-0 h-0 border-b-[39px] border-l-[39px] border-b-black border-l-transparent relative z-1">
                    <div className="absolute w-0 h-0 border-b-[35px] border-l-[35px] border-b-[#FFFFE4] border-l-transparent top-1 right-[calc(100%+2px)] -z-1"></div>
                </div>
                <div className="p-4 bg-[#FFFFE4] border-2 rounded-2xl flex flex-col items-center gap-2 min-w-[400px]">
                    {tonConnectUI.account ? (
                        <div className="flex flex-col items-stretch justify-stretch gap-3 w-full">
                            <p className="text-center">{t('account')}</p>
                            <Card className="flex items-center justify-between rounded-2xl !p-3">
                                <div className="flex flex-col items-start gap-1">
                                    <span className="text-sm">{t('user.username-visibility')}</span>
                                    <span className="text-xs">
                                        {user?.isAnon
                                            ? t('user.username-visibility.status.hidden')
                                            : t('user.username-visibility.status.showed')}
                                    </span>
                                </div>
                                <Button
                                    variant="blueGradient"
                                    className="border-black w-[140px]"
                                    onClick={changeAnonSetting}
                                >
                                    {user?.isAnon
                                        ? t('user.username-visibility.show')
                                        : t('user.username-visibility.hide')}
                                </Button>
                            </Card>
                            <Card className="flex items-center justify-between rounded-2xl !p-3 w-full">
                                <div className="flex flex-col gap-1.5">
                                    <span className="text-sm">
                                        {capitalize(t('token.balance'))}
                                    </span>
                                    <span className="text-md">
                                        {displayNanoValue(tonBalance ?? 0n, 9, 4)} TON
                                    </span>
                                    <span className="text-xs">
                                        {squeezeAddress(
                                            parseAddress(tonConnectUI.account?.address),
                                            4
                                        )}
                                    </span>
                                </div>
                                <div className="flex flex-col justify-between gap-2">
                                    <CopyButton text={parseAddress(tonConnectUI.account?.address)}>
                                        <Button variant="blueGradient" className="border-black">
                                            {t('wallet.copy-address')}
                                        </Button>
                                    </CopyButton>
                                    <Button
                                        variant="blueGradient"
                                        className="border-black"
                                        onClick={onDisconnect}
                                    >
                                        {t('wallet.disconnect')}
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Skeleton size="lg" />
                    )}
                </div>
            </div>
        </div>
    );
};
