import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { Coin } from 'coin/coin.store.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';

type Stats = {
    buyCount: string;
    sellCount: string;
    transactionsCount: string;
    buyVolume: string;
    sellVolume: string;
    volume: string;
    buyerCount: string;
    sellerCount: string;
    tradersCount: string;
    priceChangePercentage: string;
};

export enum CoinStatsPeriod {
    '5m' = '5m',
    '15m' = '15m',
    '1h' = '1h',
    '24h' = '24h',
}

interface CoinStats extends BaseEntityStore {
    stats: Record<CoinStatsPeriod, Stats | null>;
    selectedPeriod: CoinStatsPeriod;
}

type CoinStatsStore = Record<Coin['id'], CoinStats>;

const defaultState = {
    isFetched: false,
    isLoading: false,
    stats: {
        '5m': null,
        '15m': null,
        '1h': null,
        '24h': null,
    },
    selectedPeriod: CoinStatsPeriod['24h'],
};

export const $coinStats = map<CoinStatsStore>();

// actions

const getCoinStatsFromStore = (coinId: Coin['id']) => {
    return $coinStats.get()[coinId] ?? defaultState;
};

const updateCoinStats = action(
    $coinStats,
    'updateCoinStats',
    <K extends keyof CoinStats>(
        store: typeof $coinStats,
        coinId: Coin['id'],
        key: K,
        value: CoinStats[K]
    ) => {
        const currentState = getCoinStatsFromStore(coinId);
        store.setKey(coinId, {
            ...currentState,
            [key]: value,
        });
    }
);

export const fetchCoinStats = action(
    $coinStats,
    'fetchCoinStats',
    async (store, coinId: Coin['id']) => {
        updateCoinStats(coinId, 'isLoading', true);

        try {
            const period = store.get().selectedPeriod;

            const response = await authorizedFetch(
                routes.coinByIdStats(coinId) + `?period=${period}`
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    stats: Record<CoinStatsPeriod, Stats | null>;
                };

                updateCoinStats(coinId, 'stats', data.stats);
                updateCoinStats(coinId, 'isFetched', true);
            }
        } catch (e) {
            console.log(`error while fetching coinStats with ${coinId}`, e);
        } finally {
            updateCoinStats(coinId, 'isLoading', false);
        }
    }
);

export const setCoinStatsPeriod = action(
    $coinStats,
    'setCoinStatsPeriod',
    (store, coinId: Coin['id'], period: CoinStatsPeriod) => {
        updateCoinStats(coinId, 'selectedPeriod', period);
    }
);

// selectors
const selectCoinStats = computed($coinStats, (store) => store);

// hooks
export const useCoinStats = (coinId: Coin['id']) =>
    useStore(selectCoinStats)[coinId] ?? defaultState;
