import { action, computed, map, onMount } from 'nanostores';
import { useStore } from '@nanostores/react';

const LOCAL_STORAGE_KEY = 'slippage';

type SlippageStore = {
    buySlippage: bigint;
    sellSlippage: bigint;
};

export const $slippage = map<SlippageStore>({
    buySlippage: 10n,
    sellSlippage: 30n,
});

export const setBuySlippage = action($slippage, 'setBuySlippage', (store, slippage: number) => {
    try {
        const slippageBigInt = BigInt(slippage);
        store.setKey('buySlippage', slippageBigInt);
        saveStored();
    } catch (e) {
        console.log('Error setting buy slippage', e);
    }
});

export const setSellSlippage = action($slippage, 'setSellSlippage', (store, slippage: number) => {
    try {
        const slippageBigInt = BigInt(slippage);
        store.setKey('sellSlippage', slippageBigInt);
        saveStored();
    } catch (e) {
        console.log('Error setting sell slippage', e);
    }
});

const saveStored = action($slippage, 'saveStored', (store) => {
    const storedValues = store.get();
    localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
            buySlippage: Number(storedValues.buySlippage),
            sellSlippage: Number(storedValues.sellSlippage),
        })
    );
});

const selectSlippage = computed($slippage, (store) => store);

export const useSlippage = (type: 'buy' | 'sell') => {
    const slippage = useStore(selectSlippage);

    return slippage[type === 'buy' ? 'buySlippage' : 'sellSlippage'];
};

onMount($slippage, () => {
    const storedSlippage = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (storedSlippage) {
        try {
            const parsedSlippage = JSON.parse(storedSlippage);
            $slippage.setKey('buySlippage', BigInt(parsedSlippage.buySlippage));
            $slippage.setKey('sellSlippage', BigInt(parsedSlippage.sellSlippage));
        } catch {
            $slippage.setKey('buySlippage', 10n);
            $slippage.setKey('sellSlippage', 30n);
        }
    }
});
