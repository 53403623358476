import { createPersistentState } from 'common/utils/createPersistentState.ts';
import { atom, computed } from 'nanostores';
import { useStore } from '@nanostores/react';

type TgAuthStore = string | null;

const defaultValue: TgAuthStore = null;
const tgAuthPersistedStore = createPersistentState<TgAuthStore>('initData', defaultValue);

function loadFromLocalStorage(): TgAuthStore {
    return tgAuthPersistedStore.value;
}

export const $tgAuth = atom<TgAuthStore>(loadFromLocalStorage());

export const setTgAuthInitData = (initData: string) => {
    $tgAuth.set(initData);
};

const selectInitData = computed($tgAuth, (initData) => initData);

export const useTgAuth = () => useStore(selectInitData);

$tgAuth.subscribe(tgAuthPersistedStore.save);
