import { useTranslation } from 'i18n';
import { useUser } from 'user/user.store.ts';
import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { openWindowBox } from 'desktop/desktop.store.ts';
import {
    CREATE_COIN_APP,
    FRIENDS_APP,
    KING_OF_HILL_APP,
    MINESWEEPER,
    TOKEN_LIST_APP,
} from 'desktop/constants.tsx';
import { fullScreen, fullScreenCancel } from 'common/utils/fullScreen.ts';
import { ShutDownPage } from 'desktop/shutDown.page.tsx';
import { openExternalLinkInBrowser } from 'common/utils/openExternalLink.ts';

export const StartMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeMenu = () => setIsOpen(false);
    const toggleMenu = () => setIsOpen((prev) => !prev);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!tonConnectUI.account) {
            closeMenu();
        }
    }, [tonConnectUI.account]);

    const onDisconnect = async () => {
        await tonConnectUI.disconnect();
        closeMenu();
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                isOpen &&
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                closeMenu();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [isOpen, closeMenu]);

    const onAppClick = (callback: () => void) => () => {
        callback();
        closeMenu();
    };

    const [isShuttingDown, setIsShuttingDown] = useState(false);

    const onShutDown = () => {
        fullScreen(document.documentElement);
        setIsShuttingDown(true);
    };

    const cancelShutDown = () => {
        setIsShuttingDown(false);
        fullScreenCancel();
    };

    return (
        <div className="relative" ref={containerRef}>
            <button
                className="flex items-center gap-2 bigpumpButton p-2 pr-[26px] rounded-r-[16px]"
                onClick={toggleMenu}
            >
                <img src="/pump_logo.png" alt="big" className="w-[22px] h-[22px] min-w-[22px]" />
                <img src="/desktop/logo.png" alt="pump" className="w-[63px] min-w-[63px] mt-0.5" />
            </button>
            <div
                className={classNames(
                    'absolute top-full left-0 w-[350px] startMenu__container rounded-sm',
                    {
                        hidden: !isOpen,
                    }
                )}
            >
                <div className="startMenu__header flex items-center gap-1.5 p-1.5 ">
                    <img
                        src="/desktop/user_icon.png"
                        alt="pocketfi"
                        className="w-9 aspect-square rounded-lg border-2 border-[#DADADA]"
                    />
                    <span className="text-sm text-white loginScreen__text_shadow">User</span>
                </div>
                <div className="relative loginScreen__bg_bottom border-x-2 border-[#478BEB] grid grid-cols-2 min-h-[240px]">
                    <div className="bg-white border border-[#98A4F9] px-1.5 py-2.5 gap-3 flex flex-col items-start">
                        <AppShortcut
                            onClick={onAppClick(() => openWindowBox(CREATE_COIN_APP))}
                            image={CREATE_COIN_APP.icon}
                            name={CREATE_COIN_APP.title}
                        />
                        <AppShortcut
                            onClick={onAppClick(() => openWindowBox(FRIENDS_APP))}
                            image={FRIENDS_APP.icon}
                            name={FRIENDS_APP.title}
                        />
                        <div className="startMenu__horizontalDivider" />
                        <AppShortcut
                            onClick={onAppClick(() => openWindowBox(TOKEN_LIST_APP))}
                            image={TOKEN_LIST_APP.icon}
                            name={TOKEN_LIST_APP.title}
                        />
                        <AppShortcut
                            onClick={onAppClick(() => openWindowBox(KING_OF_HILL_APP))}
                            image={KING_OF_HILL_APP.icon}
                            name={KING_OF_HILL_APP.title}
                        />
                    </div>
                    <div className="bg-[#D0E2FC] border border-[#98A4F9] px-1.5 py-2.5 gap-3 flex flex-col items-start">
                        <AppShortcut
                            onClick={onAppClick(() =>
                                openExternalLinkInBrowser('https://t.me/pocketfi')
                            )}
                            image="/desktop/apps/pocketfi_news.png"
                            name="PocketFi News"
                        />
                        <AppShortcut
                            onClick={onAppClick(() =>
                                openExternalLinkInBrowser('https://t.me/pocketfi_bot/Mining')
                            )}
                            image="/desktop/apps/pocketfi.png"
                            name="PocketFi"
                        />
                        <div className="startMenu__horizontalDivider" />
                        <AppShortcut
                            onClick={onAppClick(() => openWindowBox(MINESWEEPER))}
                            image="/desktop/apps/minesweeper.png"
                            name="Minesweeper"
                        />
                        {/*<AppShortcut*/}
                        {/*    onClick={onAppClick(() => {})}*/}
                        {/*    image="/desktop/apps/winamp.png"*/}
                        {/*    name="Winamp"*/}
                        {/*/>*/}
                    </div>
                </div>
                <div className="bg-gradient-to-b from-[#478BEB] to-[#175ACC] p-1.5 flex items-center justify-end gap-3">
                    <button
                        className="flex items-center gap-1 text-white text-2xs"
                        onClick={onDisconnect}
                    >
                        <img
                            src="/desktop/log_off.png"
                            alt="log off"
                            className="w-5 h-5 rounded border border-white"
                        />
                        <span>Log Off</span>
                    </button>
                    <button
                        className="flex items-center gap-1 text-white text-2xs"
                        onClick={onShutDown}
                    >
                        <img
                            src="/desktop/turn_off.png"
                            alt="log off"
                            className="w-5 h-5 rounded border border-white"
                        />
                        <span>Turn Off Computer</span>
                    </button>
                </div>
            </div>
            {isShuttingDown && <ShutDownPage onClose={cancelShutDown} />}
        </div>
    );
};

type AppShortcutProps = {
    onClick: () => void;
    image: string;
    name: string;
};

const AppShortcut: FC<AppShortcutProps> = ({ onClick, image, name }) => {
    return (
        <button className="flex items-center gap-1.5" onClick={onClick}>
            <img src={image} alt={name} className="w-[30px] aspect-square" />
            <span className="text-xs">{name}</span>
        </button>
    );
};
