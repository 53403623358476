/**
 * Создаёт обвязку для работы с localStorage, обеспечивая типизацию данных.
 *
 * @param key — ключ для хранения в localStorage.
 * @param defaultValue — дефолтное значение, которое будет возвращаться, если по указанному ключу ничего не сохранено.
 * @returns Объект с геттером `value` для чтения текущего значения и методом `save` для сохранения нового значения.
 */
export function createPersistentState<T>(
    key: string,
    defaultValue: T
): { value: T; save: (newValue: T) => void } {
    return {
        get value(): T {
            try {
                const storedValue = localStorage.getItem(key);
                if (storedValue !== null) {
                    return JSON.parse(storedValue) as T;
                }
            } catch (error) {
                console.error(`Ошибка чтения из localStorage по ключу "${key}":`, error);
            }
            return defaultValue;
        },

        save(newValue: T): void {
            try {
                localStorage.setItem(key, JSON.stringify(newValue));
            } catch (error) {
                console.error(`Ошибка сохранения в localStorage по ключу "${key}":`, error);
            }
        },
    };
}
