import { useEffect } from 'react';
import { setHeaderPrimaryColor, useBackButton } from 'common/utils/tgUtilt.ts';
import { ICCard } from 'inviteContest/components/icCard.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { ICButton } from 'inviteContest/components/icButton.tsx';
import { useTranslation } from 'i18n';
import { SWITCH_REWARD_AMOUNT, USDT_REWARD_AMOUNT } from 'inviteContest/constants.ts';
import { useInviteContestTimeLeft } from 'inviteContest/hooks.ts';

export const InviteContestPage = () => {
    const { t } = useTranslation();
    useEffect(() => {
        setHeaderPrimaryColor();
    }, []);

    useBackButton({
        from: '/invite-contest',
        callback: navigation.mainPage,
    });

    const timeLeft = useInviteContestTimeLeft();

    return (
        <div className="bg-invite-contest-primary h-screen bg-[url('/inviteContest/invite-contest-2-bg.png')] bg-[center_top_-7rem] bg-cover bg-no-repeat flex flex-col items-center py-1 px-4 pt-3 pb-6 font-Wix overflow-y-auto">
            <img
                src="/inviteContest/contest-logo-2.png"
                alt="fren"
                className="w-[80%] tall:w-[70%]"
            />
            <ICCard className="-mt-3 p-6 flex flex-col items-center gap-5 tall:gap-3 w-full">
                <div className="flex flex-col items-center gap-3 tall:gap-2">
                    <h1 className="text-xl text-center font-bold tall:text-lg">
                        {t('invite-contest.title')}
                    </h1>
                    <div className="bg-gradient-to-b from-[#2AC58A] to-[#BB73FF] text-white px-4 py-3 rounded-[40px]">
                        {timeLeft}
                    </div>
                </div>
                <ICCard className="bg-[#F8F8F8] px-6 py-3">
                    {[
                        t('invite-contest.intro.trade-crypto'),
                        t('invite-contest.intro.invite-frens'),
                        t('invite-contest.intro.receive-rewards'),
                    ].map((text) => (
                        <div key={text} className="flex items-center gap-3">
                            <img
                                src="/inviteContest/solar_star-bold.svg"
                                alt=""
                                className="w-4 h-4"
                            />
                            <span>{text}</span>
                        </div>
                    ))}
                </ICCard>
                <div className="flex items-center gap-1">
                    <img src="/inviteContest/branch.png" alt="" className="w-8" />
                    <p
                        className="text-lg font-normal text-center tall:text-md"
                        dangerouslySetInnerHTML={{
                            __html: t('invite-contest.intro.prize-amount')
                                .replace('{usdtAmount}', USDT_REWARD_AMOUNT.toString(10))
                                .replace('{switchAmount}', SWITCH_REWARD_AMOUNT.toString(10))
                                .replace(/<>(.*?)<\/>/gi, '<span class="font-bold">$1</span>'),
                        }}
                    />
                    <img src="/inviteContest/branch.png" alt="" className="w-8 scale-x-[-1]" />
                </div>
            </ICCard>
            <div className="mt-auto py-2 flex flex-col items-stretch gap-2 w-full">
                <ICButton className="bg-dark-yellow flex-1" onClick={navigation.joinContest}>
                    {t('invite-contest.intro.join-event')}
                </ICButton>
                <ICButton onClick={navigation.contestHowTo}>
                    {t('invite-contest.how-to-start')}
                </ICButton>
            </div>
        </div>
    );
};
