import { action, computed, map } from 'nanostores';
import { BaseEntityStore } from 'common/constants/types.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { uniqBy } from 'lodash-es';
import { Coin } from 'coin/coin.store.ts';

export type ReactionStates = {
    likeCount: number;
    dislikeCount: number;
    selfLike: boolean;
    selfDislike: boolean;
};

export type CommunityNote = {
    id: string;
    coinId: string;
    updatedAt: string;
    content: string;
    username: string;
} & ReactionStates;

interface CommunityNotes extends BaseEntityStore {
    notes: CommunityNote[];
    cursor?: string;
    sortType: 'likeCount' | 'id';
}

const defaultState = {
    isFetched: false,
    notes: [],
    sortType: 'likeCount' as const,
};

type CommunityNotesStore = Record<Coin['id'], CommunityNotes>;

export const $communityNotesStore = map<CommunityNotesStore>();

// actions

const getCommunityNotesFromStore = (coinId: Coin['id']) => {
    return $communityNotesStore.get()[coinId] ?? defaultState;
};

const updateCommunityNotes = action(
    $communityNotesStore,
    'updateCommunityNotes',
    <K extends keyof CommunityNotes>(
        store: typeof $communityNotesStore,
        coinId: Coin['id'],
        key: K,
        value: CommunityNotes[K]
    ) => {
        const currentState = getCommunityNotesFromStore(coinId);
        store.setKey(coinId, {
            ...currentState,
            [key]: value,
        });
    }
);

export const fetchCommunityNotes = action(
    $communityNotesStore,
    'fetchCommunityNotes',
    async (store, coinId: string, sortType?: 'likeCount' | 'id') => {
        if (store.get().isLoading) return;
        updateCommunityNotes(coinId, 'isLoading', true);

        try {
            const sortTypeStored = getCommunityNotesFromStore(coinId).sortType;
            const type = sortType ?? sortTypeStored;
            const currentCursor = getCommunityNotesFromStore(coinId).cursor;

            const searchParams = new URLSearchParams({
                sortType: type,
            });

            if (currentCursor) {
                searchParams.append('cursor', currentCursor);
            }

            const response = await authorizedFetch(
                routes.communityNotes + '/' + coinId + '?' + searchParams.toString()
            );

            if (response.ok) {
                const { notes, nextCursor } = (await response.json()) as {
                    notes: CommunityNote[];
                    nextCursor: string | null;
                };
                updateCommunityNotes(
                    coinId,
                    'notes',
                    uniqBy([...getCommunityNotesFromStore(coinId).notes, ...notes], 'id')
                );
                updateCommunityNotes(coinId, 'cursor', nextCursor ?? undefined);
                updateCommunityNotes(coinId, 'isFetched', true);
            }
        } catch (e) {
            console.error('fetchCommunityNotes error', e);
        } finally {
            updateCommunityNotes(coinId, 'isLoading', false);
        }
    }
);

export const addCommunityNote = action(
    $communityNotesStore,
    'addCommunityNote',
    async (store, { coinId, content }: { coinId: string; content: string }) => {
        try {
            const response = await authorizedFetch(routes.communityNotes, {
                method: 'POST',
                body: JSON.stringify({ coinId, content }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                fetchCommunityNotes(coinId);
            }
        } catch (e) {
            console.error('addCommunityNote error', e);
        }
    }
);

export const updateReaction = action(
    $communityNotesStore,
    'updateReaction',
    async (
        store,
        {
            noteId,
            reactionType,
            coinId,
        }: { noteId: string; reactionType: 'like' | 'dislike'; coinId: Coin['id'] }
    ) => {
        try {
            const response = await authorizedFetch(routes.communityNotes + '/updateReaction', {
                method: 'POST',
                body: JSON.stringify({ communityNoteId: noteId, reactionType }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const notes = getCommunityNotesFromStore(coinId).notes;
                updateCommunityNotes(
                    coinId,
                    'notes',
                    notes.map((note) => {
                        if (note.id === noteId) {
                            const isLikeAdded = reactionType === 'like' && !note.selfLike;
                            const isDislikeAdded = reactionType === 'dislike' && !note.selfDislike;

                            return {
                                ...note,
                                selfLike: isLikeAdded,
                                selfDislike: isDislikeAdded,
                                likeCount: isLikeAdded
                                    ? note.likeCount + 1
                                    : !isLikeAdded && note.selfLike
                                      ? note.likeCount - 1
                                      : note.likeCount,
                                dislikeCount: isDislikeAdded
                                    ? note.dislikeCount + 1
                                    : !isDislikeAdded && note.selfDislike
                                      ? note.dislikeCount - 1
                                      : note.dislikeCount,
                            };
                        }
                        return note;
                    })
                );
            }
        } catch (e) {
            console.error('updateReaction error', e);
        }
    }
);

export const clearCommunityNotes = action(
    $communityNotesStore,
    'clearCommunityNotes',
    (store, coinId: Coin['id']) => {
        updateCommunityNotes(coinId, 'notes', []);
        updateCommunityNotes(coinId, 'isFetched', false);
        updateCommunityNotes(coinId, 'cursor', undefined);
    }
);

export const setSortType = action(
    $communityNotesStore,
    'setSortType',
    (store, coinId: Coin['id'], sortType: 'likeCount' | 'id') => {
        updateCommunityNotes(coinId, 'sortType', sortType);
        clearCommunityNotes(coinId);
    }
);

// selectors

const selectCommunityNotes = computed($communityNotesStore, (store) => store);

// hooks
export const useCommunityNotes = (coinId: Coin['id']) => {
    const notes = useStore(selectCommunityNotes)[coinId] ?? defaultState;

    return {
        ...notes,
        canFetchMore: Boolean(notes.cursor),
        isFetching: notes.isLoading && !notes.isFetched,
    };
};
