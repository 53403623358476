import {
    getCoinsList,
    searchCoins,
    setCoinListSearch,
    useCoinsList,
} from 'main/coinsList.store.ts';
import { Card } from 'common/components/card.tsx';
import { Input } from 'common/components/input.tsx';
import { Button } from 'common/components/button.tsx';
import { Tabs } from 'common/components/tabs.tsx';
import { useTranslation } from 'i18n';
import { CoinsGrid } from 'desktop/windows/coinsGrid.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';

export const Coins = () => {
    const { t } = useTranslation();

    useAutoFetch(getCoinsList, 3000);
    const { search, searchResults } = useCoinsList();

    return (
        <Card className="!bg-blue bg-[url('/desktop/apps/сoins_bg.gif')] bg-cover bg-no-repeat !p-4 flex flex-col gap-1 h-full overflow-y-auto">
            <div className="flex items-center w-full justify-stretch">
                <Input
                    placeholder={t('main.searchTokenPlaceholder')}
                    onChange={setCoinListSearch}
                    value={search}
                    className="!text-2xs"
                />
                <Button
                    variant="blueGradient"
                    className="border-black px-4 py-2 !text-2xs border-l-0"
                    onClick={searchCoins}
                >
                    {t('main.searchToken')}
                </Button>
            </div>
            {!searchResults.length && <Tabs containerClassName="min-h-[68px]" limit={20} />}
            <CoinsGrid />
        </Card>
    );
};
