import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useTranslation } from 'i18n';
import { unlockTokens, useCoinById } from 'coin/coin.store.ts';
import { Drawer } from 'common/components/drawer.tsx';
import { FC, useEffect, useRef, useState } from 'react';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTon } from 'main/main.store.ts';
import { useOperationDrawer } from 'coin/components/operationInProcess.tsx';
import classNames from 'classnames';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

type UnlockTokensProps = {
    coinId: string;
};

export const UnlockTokens: FC<UnlockTokensProps> = ({ coinId }) => {
    const { t } = useTranslation();
    const { isUnlocked, coinBalance } = useCoinById(coinId);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { price } = useTon();

    const { OperationDrawer, open } = useOperationDrawer();

    const onUnlock = async () => {
        await unlockTokens(coinId);
        open();
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const scrollToBuyButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        if (!buttonRef.current) {
            return;
        }
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsButtonVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        observer.observe(buttonRef.current as Element);
        return () => {
            observer.disconnect();
        };
    }, [buttonRef.current]);

    if (
        (isUnlocked === null && coinBalance === undefined) ||
        isUnlocked ||
        coinBalance === '0' ||
        !tonConnectUI.connected
    ) {
        return null;
    }

    return (
        <>
            <BigColorButton onClick={() => setIsDrawerOpen(true)} ref={buttonRef}>
                {t('token.unlock-tokens').toUpperCase()}
            </BigColorButton>
            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} position="bottom">
                <div className="flex flex-col items-center pb-4">
                    <TextWithShadow className="mb-3 italic">
                        {t('token.unlock-tokens.title').toUpperCase()}
                    </TextWithShadow>
                    <p
                        className="font-normal text-white text-center mb-10 px-4"
                        dangerouslySetInnerHTML={{
                            __html: t('token.unlock-tokens.description')
                                .replace(/<>(.*?)<\/>/gi, '<span class="font-bold">$1</span>')
                                .replace(/\n/g, '<br />')
                                .replace('{totalLiquidity}', `${1500 * price}`),
                        }}
                    />
                    <BigColorButton className="h-12" onClick={onUnlock}>
                        {t('token.unlock-tokens').toUpperCase()}
                    </BigColorButton>
                </div>
            </Drawer>
            {OperationDrawer}
            <button
                className={classNames(
                    'fixed bottom-[100px] left-7 z-30 bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] rounded-xl create-token-shadow w-[calc(100%-3.5rem)]',
                    { hidden: isButtonVisible }
                )}
                onClick={scrollToBuyButton}
            >
                <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                    <TextWithShadow className="italic">
                        {t('token.unlock-tokens').toUpperCase()}
                    </TextWithShadow>
                </div>
            </button>
        </>
    );
};
