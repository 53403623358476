import { getCoinsList, pullNewCoinsList, useCoinsList } from 'main/coinsList.store.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { List } from 'common/components/list.tsx';
import { CoinListCard } from 'coin/components/coinListCard.tsx';

export const CoinsList = () => {
    const { coins, isFetching, canFetchMore, search, searchResults, sortType } = useCoinsList();

    useAutoFetch(pullNewCoinsList, 3000);

    const coinsToDisplay = search && searchResults.length ? searchResults : coins;

    return (
        <List
            entities={coinsToDisplay}
            fetchEntities={getCoinsList}
            isFetching={isFetching}
            canFetchMore={canFetchMore}
            keyField="id"
            component={({ entity: coin }) => <CoinListCard coin={coin} sortType={sortType} />}
            skeletonHeight={150}
        />
    );
};
