import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { Coin } from 'coin/coin.store.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';

export type THolder = {
    coinAddress: string;
    actorAddress: string;
    username: string | null;
    userId: string | null;
    sum: string;
    isAuthor: boolean;
};

interface HolderStore extends BaseEntityStore {
    holders: THolder[];
}

type HoldersStore = Record<Coin['id'], HolderStore>;

const defaultState = {
    isFetched: false,
    isLoading: false,
    holders: [],
};

export const $holders = map<HoldersStore>();

// actions

const getHoldersFromStore = (coinId: Coin['id']) => {
    return $holders.get()[coinId] ?? defaultState;
};

const updateHolders = action(
    $holders,
    'updateHolders',
    <K extends keyof HolderStore>(
        store: typeof $holders,
        coinId: Coin['id'],
        key: K,
        value: HolderStore[K]
    ) => {
        const currentState = getHoldersFromStore(coinId);
        store.setKey(coinId, {
            ...currentState,
            [key]: value,
        });
    }
);

export const fetchHolders = action($holders, 'fetchHolders', async (store, coinId: Coin['id']) => {
    updateHolders(coinId, 'isLoading', true);
    try {
        const response = await authorizedFetch(routes.coinByIdHolders(coinId));

        if (!response.ok) {
            console.log('Failed to fetch holders', response);
            return;
        }

        const data = await response.json();

        updateHolders(coinId, 'holders', data.holders);
        updateHolders(coinId, 'isFetched', true);
    } catch (e) {
        console.log('Failed to fetch holders', e);
    } finally {
        updateHolders(coinId, 'isLoading', false);
    }
});

// selectors
const selectHolders = computed($holders, (store) => store);

export const useHolders = (coinId: Coin['id']) => {
    const holders = useStore(selectHolders);

    return holders[coinId] ?? defaultState;
};
