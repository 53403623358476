import { initTonConnect } from './tonConnect/init.ts';
import { MainLayout } from 'common/components/mainLayout.tsx';
import { MainPage } from 'main/main.page.tsx';
import { useEffect } from 'react';
import { TokenPage } from 'coin/token.page.tsx';
import { TokenPageMenu } from 'coin/tokenPageMenu.tsx';
import { TokenTradeTab } from 'coin/tabs/tokenTradeTab.tsx';
import { TokenAboutTab } from 'coin/tabs/tokenAboutTab.tsx';
import { onTonConnectStatusChange, tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonPrice } from 'main/main.store.ts';
import { NotificationRow } from 'main/notificationRow.tsx';
import { FrensPage } from 'frens/frens.page.tsx';
import { fetchMe, useUser } from 'user/user.store.ts';
import { WalletConnectModal } from 'tonConnect/walletConnectModal.tsx';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { CreateCoinPage } from 'createToken/createCoin.page.tsx';
import { initPocketFiBridge } from 'tonConnect/pocketfi.ts';
import { LoadingPage } from 'preloading/loading.page.tsx';
import {
    useIsTonConnectReady,
    useRestoreTonConnect,
    useTonConnectConnectionStatus,
} from 'tonConnect/tonConnect.store.ts';
import WebApp from '@twa-dev/sdk';
import { CabalArenaPage } from 'cabalArena/cabalArena.page.tsx';
import { initTonProofPayload, onTonProofConnect } from 'tonkeeper/hooks.ts';
import { IS_DESKTOP, IS_TON_KEEPER } from 'common/constants';
import { parseUtmMarks } from 'common/utils/parseUtmMarks.ts';
import isEmpty from 'lodash-es/isEmpty';
import { gtag } from 'gtag.ts';
import { LinkGeneratorPage } from 'linkGenerator/linkGenerator.page.tsx';
import { getCoinIdByAddress } from 'coin/coin.store.ts';
import { setIsVisible } from 'app.store.ts';
import { Route, Routes, useLocation } from 'react-router';
import PostHogPageView from 'posthog/PosthogPageView';
import { InviteContestPage } from 'inviteContest/inviteContest.page.tsx';
import { JoinContestPage } from 'inviteContest/joinContest.page.tsx';
import { ContestRewardsPage } from 'inviteContest/contestRewards.page.tsx';
import { HowToContestPage } from 'inviteContest/howToContest.page.tsx';
import { ContestLeaderboard } from 'inviteContest/contestLeaderboard.tsx';
import { useCloudStorage } from 'common/hooks/useCloudStorage.ts';
import { DesktopPage } from 'desktop/desktop.page.tsx';
import { Test } from 'test.tsx';
import { DesktopLoginPage } from 'desktop/login.page.tsx';

initTonConnect();
initPocketFiBridge();
initTonProofPayload();

export function App() {
    const { isFetched } = useUser();
    const isReady = useIsTonConnectReady();
    const { pathname } = useLocation();

    useAutoFetch(fetchMe);

    useRestoreTonConnect();

    useEffect(() => {
        const onVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible');
        };

        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    if (pathname === '/link-generator') {
        return <LinkGeneratorPage />;
    }

    if ((!isFetched && !IS_TON_KEEPER && !IS_DESKTOP) || !isReady) {
        return <LoadingPage />;
    }

    return <AppRouter />;
}

function AppRouter() {
    useEffect(() => {
        tonConnectUI.onStatusChange(
            async (wallet) => {
                if ((IS_TON_KEEPER || IS_DESKTOP) && wallet?.device.appName !== 'pocketfi') {
                    console.log('wallet', wallet);
                    console.log(IS_TON_KEEPER, IS_DESKTOP, wallet?.device.appName !== 'pocketfi');
                    await onTonProofConnect(wallet);
                }
                await onTonConnectStatusChange(wallet);
            },
            (err) => {
                if (err) {
                    console.log(err);
                    tonConnectUI.disconnect();
                }
            }
        );

        const savedInitData = sessionStorage.getItem('initData');

        if (savedInitData !== WebApp.initData && WebApp.initData) {
            sessionStorage.setItem('initData', WebApp.initData);
            const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

            if (startParams?.params) {
                const utmMarks = parseUtmMarks(startParams.params);
                if (!isEmpty(utmMarks)) {
                    gtag('utm_data', { ...utmMarks, ref_code: startParams.referralCode || 'none' });
                }
                if ('coinId' in startParams.params) {
                    navigation.tokenTrade(startParams.params.coinId)();
                } else if ('address' in startParams.params) {
                    getCoinIdByAddress(startParams.params.address);
                }
            }
        }
    }, []);

    useEffect(() => {
        const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

        if (startParams.params && 'invite_contest' in startParams.params) {
            navigation.inviteContest();
        }
    }, []);

    useAutoFetch(fetchTonPrice, 60000);

    const [inviteContest] = useCloudStorage('tradeContest2', { joined: false });

    const isWalletConnected = useTonConnectConnectionStatus();

    if (IS_DESKTOP) {
        if (!isWalletConnected) {
            return <DesktopLoginPage />;
        }
        return <DesktopPage />;
    }

    // useCabalArenaPage();

    return (
        <>
            <PostHogPageView />
            <Routes>
                <Route path="/" element={<MainLayout header={<NotificationRow />} />}>
                    <Route index element={<MainPage />} />
                </Route>

                <Route path="/mytest" element={<Test />}></Route>

                <Route path="/create" element={<CreateCoinPage />} />

                <Route path="/token/:tokenId" element={<MainLayout footer={<TokenPageMenu />} />}>
                    <Route
                        path="trade"
                        element={
                            <TokenPage>
                                <TokenTradeTab />
                            </TokenPage>
                        }
                    />

                    <Route
                        path="about"
                        element={
                            <TokenPage>
                                <TokenAboutTab />
                            </TokenPage>
                        }
                    />
                </Route>

                <Route path="/frens" element={<MainLayout color="#1A1A1A" />}>
                    <Route index element={<FrensPage />} />
                </Route>

                <Route path="/cabal-arena" element={<CabalArenaPage />} />
                <Route path="/invite-contest">
                    <Route
                        index
                        element={inviteContest.joined ? <JoinContestPage /> : <InviteContestPage />}
                    />

                    <Route path="join" element={<JoinContestPage />} />

                    <Route path="rewards" element={<ContestRewardsPage />} />

                    <Route path="how-to" element={<HowToContestPage />} />

                    <Route path="leaderboard" element={<ContestLeaderboard />} />
                </Route>
            </Routes>
            <WalletConnectModal />
        </>
    );
}
