import { Skeleton } from 'common/components/skeleton.tsx';
import classNames from 'classnames';
import { Card } from 'common/components/card.tsx';
import { Coin } from 'coin/coin.store.ts';
import { useTranslation } from 'i18n';
import { useCallback } from 'react';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { EmptyList } from 'common/components/emptyList.tsx';
import { Transaction } from 'coin/transactions/transaction.tsx';
import {
    fetchCoinTransactions,
    pullCoinTransactions,
    useCoinTransactions,
} from 'coin/transactions/coinTransactions.store.ts';

export const TransactionHistory = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { transactions, isFetching, canFetchMore } = useCoinTransactions(tokenId);
    const fetchTransactions = useCallback(() => fetchCoinTransactions(tokenId), [tokenId]);
    const pullTransactions = useCallback(() => pullCoinTransactions(tokenId), [tokenId]);
    useAutoFetch(fetchTransactions);
    useAutoFetch(pullTransactions, 5000, 5000);
    const anchorRef = useScrollToFetchData(fetchTransactions);
    const { t } = useTranslation();

    return (
        <Card className="!bg-gray !p-4 flex flex-col gap-3 mb-10">
            <p className="text-white-gray text-center">{t('token.transactions.latest')}</p>
            <div className="flex flex-col gap-3">
                {isFetching && (
                    <>
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                    </>
                )}
                {!isFetching && !transactions.length && <EmptyList color="#CDCDCD" />}
                {transactions.map((transaction) => (
                    <div
                        key={transaction.txHash}
                        className="pb-3 border-b border-white-gray last:pb-0 last:border-b-0"
                    >
                        <Transaction transaction={transaction} />
                    </div>
                ))}
                <div
                    className={classNames('w-full flex flex-col gap-3', {
                        [`hidden`]: !canFetchMore,
                    })}
                    ref={anchorRef}
                >
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                </div>
            </div>
        </Card>
    );
};
