import { FC, useEffect, useReducer, useState } from 'react';
import sampleSize from 'lodash-es/sampleSize';

import { Config } from './config';
import { MineSweeperView } from './MinesweeperView.tsx';

export type Difficulty = 'Beginner' | 'Intermediate' | 'Expert';

// Интерфейс для одной ячейки (ceil)
export interface Ceil {
    state: 'cover' | 'open' | 'flag' | 'unknown' | 'mine' | 'die' | 'misflagged' | string;
    minesAround: number;
    opening: boolean;
    walked?: boolean;
}

// Интерфейс состояния игры
interface GameState {
    difficulty: Difficulty;
    status: 'new' | 'started' | 'died' | 'won' | string;
    rows: number;
    columns: number;
    mines: number;
    ceils: Ceil[];
}

// Типы экшенов для редьюсера
type Action =
    | { type: 'CLEAR_MAP'; payload?: Difficulty }
    | { type: 'START_GAME'; payload: number } // payload — индекс ячейки, которую надо исключить
    | { type: 'OPEN_CEIL'; payload: number }
    | { type: 'CHANGE_CEIL_STATE'; payload: number }
    | { type: 'GAME_OVER'; payload: number }
    | { type: 'WON' }
    | { type: 'OPENING_CEIL'; payload: number }
    | { type: 'OPENING_CEILS'; payload: number };

// Функция инициализации состояния на основе сложности
function getInitState(difficulty: Difficulty = 'Beginner'): GameState {
    return {
        difficulty,
        status: 'new',
        ...genGameConfig(Config[difficulty]),
    };
}

// Редьюсер для обработки экшенов
function reducer(state: GameState, action: Action): GameState {
    switch (action.type) {
        case 'CLEAR_MAP': {
            const difficulty = action.payload || state.difficulty;
            return getInitState(difficulty);
        }
        case 'START_GAME': {
            const exclude = action.payload;
            return {
                ...state,
                ...insertMines({ ...Config[state.difficulty], exclude }, state.ceils),
                status: 'started',
            };
        }
        case 'OPEN_CEIL': {
            const indexes = autoCeils(state, action.payload);
            const ceils = [...state.ceils];
            indexes.forEach((i) => {
                const ceil = ceils[i];
                ceils[i] = { ...ceil, state: 'open' };
            });
            return {
                ...state,
                ceils,
            };
        }
        case 'CHANGE_CEIL_STATE': {
            const index = action.payload;
            const ceils = [...state.ceils];
            const ceil = state.ceils[index];
            let newState: string;
            switch (ceil.state) {
                case 'cover':
                    newState = 'flag';
                    break;
                case 'flag':
                    newState = 'unknown';
                    break;
                case 'unknown':
                    newState = 'cover';
                    break;
                default:
                    throw new Error(`Unknown ceil state ${ceil.state}`);
            }
            ceils[index] = { ...ceil, state: newState };
            return {
                ...state,
                ceils,
            };
        }
        case 'GAME_OVER': {
            const ceils = state.ceils.map((ceil) => {
                if (ceil.minesAround < 0 && ceil.state !== 'flag') {
                    return { ...ceil, state: 'mine' };
                } else if (ceil.state === 'flag' && ceil.minesAround >= 0) {
                    return { ...ceil, state: 'misflagged' };
                } else {
                    return { ...ceil, opening: false };
                }
            });
            ceils[action.payload].state = 'die';
            return { ...state, status: 'died', ceils };
        }
        case 'WON': {
            const ceils = state.ceils.map((ceil) => {
                if (ceil.minesAround >= 0) {
                    return { ...ceil, state: 'open' };
                } else {
                    return { ...ceil, state: 'flag' };
                }
            });
            return { ...state, status: 'won', ceils };
        }
        case 'OPENING_CEIL': {
            const ceil = state.ceils[action.payload];
            const ceils = state.ceils.map((ceil) => ({ ...ceil, opening: false }));
            ceils[action.payload] = { ...ceil, opening: true };
            return { ...state, ceils };
        }
        case 'OPENING_CEILS': {
            const indexes = getNearIndexes(action.payload, state.rows, state.columns);
            const ceils = state.ceils.map((ceil) => ({ ...ceil, opening: false }));
            [...indexes, action.payload].forEach((index) => {
                const ceil = { ...ceils[index] };
                ceil.opening = true;
                ceils[index] = ceil;
            });
            return { ...state, ceils };
        }
        default:
            return state;
    }
}

interface MineSweeperProps {
    defaultDifficulty?: Difficulty;
    onClose: () => void;
    sameTouchPos?: boolean;
    lastTouch?: number;
    uuid: string;
}

export const MineSweeper: FC<MineSweeperProps> = ({
    defaultDifficulty = 'Beginner',
    onClose,
    sameTouchPos = false,
    lastTouch = Date.now(),
    uuid,
}) => {
    const [state, dispatch] = useReducer(reducer, getInitState(defaultDifficulty));
    const seconds = useTimer(state.status);

    function changeCeilState(index: number): void {
        const ceil = state.ceils[index];
        if (ceil.state === 'open' || ['won', 'died'].includes(state.status)) return;
        dispatch({ type: 'CHANGE_CEIL_STATE', payload: index });
    }

    function openCeil(index: number): void {
        switch (state.status) {
            case 'new':
                dispatch({ type: 'START_GAME', payload: index });
                dispatch({ type: 'OPEN_CEIL', payload: index });
                break;
            case 'started': {
                const ceil = state.ceils[index];
                if (!ceil) {
                    console.log(index);
                    console.log(state.ceils);
                    console.log(state.rows);
                    console.log(state.columns);
                    console.log(state.ceils.length);
                    console.log(state.ceils[index]);
                    break;
                }
                if (['flag', 'open'].includes(ceil.state)) {
                    break;
                } else if (ceil.minesAround < 0) {
                    dispatch({ type: 'GAME_OVER', payload: index });
                } else {
                    dispatch({ type: 'OPEN_CEIL', payload: index });
                }
                break;
            }
            default:
                console.log(state.status);
        }
    }

    function openCeils(index: number): void {
        const ceil = state.ceils[index];
        if (ceil.state !== 'open' || ceil.minesAround <= 0 || state.status !== 'started') return;
        const indexes = getNearIndexes(index, state.rows, state.columns);
        const nearCeils = indexes.map((i) => state.ceils[i]);
        if (nearCeils.filter((ceil) => ceil.state === 'flag').length !== ceil.minesAround) return;
        const mineIndex = indexes.find(
            (i) => state.ceils[i].minesAround < 0 && state.ceils[i].state !== 'flag'
        );
        if (mineIndex !== undefined) {
            dispatch({ type: 'GAME_OVER', payload: mineIndex });
        } else {
            indexes.forEach((i) => dispatch({ type: 'OPEN_CEIL', payload: i }));
        }
    }

    useEffect(() => {
        if (state.status === 'started' && checkRemains() === 0) {
            dispatch({ type: 'WON' });
        }
    });

    function onReset(difficulty?: Difficulty): void {
        dispatch({ type: 'CLEAR_MAP', payload: difficulty });
    }

    function checkRemains(): number {
        const safeCeils = state.ceils
            .filter((ceil) => ceil.state !== 'open')
            .filter((ceil) => ceil.minesAround >= 0);
        return safeCeils.length;
    }

    function openingCeil(index: number): void {
        if (['died', 'won'].includes(state.status)) return;
        dispatch({ type: 'OPENING_CEIL', payload: index });
    }

    function openingCeils(index: number): void {
        if (['died', 'won'].includes(state.status)) return;
        dispatch({ type: 'OPENING_CEILS', payload: index });
    }

    return (
        <MineSweeperView
            {...state}
            onClose={onClose}
            changeCeilState={changeCeilState}
            openCeil={openCeil}
            openCeils={openCeils}
            onReset={onReset}
            seconds={seconds}
            openingCeil={openingCeil}
            openingCeils={openingCeils}
            sameTouchPos={sameTouchPos}
            lastTouch={lastTouch}
            uuid={uuid}
        />
    );
};

// Генерация конфигурации игры на основе переданных параметров
function genGameConfig(config: {
    rows: number;
    columns: number;
    mines: number;
}): Omit<GameState, 'difficulty' | 'status'> {
    const { rows, columns, mines } = config;
    const ceils: Ceil[] = Array(rows * columns)
        .fill(null)
        .map(() => ({
            state: 'cover',
            minesAround: 0,
            opening: false,
        }));
    return {
        rows,
        columns,
        ceils,
        mines,
    };
}

// Функция вставки мин в карту
function insertMines(
    config: { rows: number; columns: number; mines: number; exclude?: number },
    originCeils: Ceil[]
): Omit<GameState, 'difficulty' | 'status'> {
    const { rows, columns, mines, exclude } = config;
    const ceils = originCeils.map((ceil) => ({ ...ceil }));
    if (rows * columns !== ceils.length) throw new Error('rows and columns not equal to ceils');
    const indexArray = [...Array(rows * columns).keys()];
    sampleSize(
        indexArray.filter((i) => i !== exclude),
        mines
    ).forEach((chosen: number) => {
        ceils[chosen].minesAround = -10;
        getNearIndexes(chosen, rows, columns).forEach((nearIndex) => {
            ceils[nearIndex].minesAround += 1;
        });
    });
    return { rows, columns, ceils, mines };
}

// Функция автоматического открытия ячеек
function autoCeils(state: GameState, index: number): number[] {
    const ceils = state.ceils.map((ceil) => ({ ...ceil, walked: false }));
    return walkCeils(index);

    function walkCeils(index: number): number[] {
        const ceil = ceils[index];
        if (!ceil) return [];
        if (ceil.walked || ceil.minesAround < 0 || ceil.state === 'flag') return [];
        ceil.walked = true;
        if (ceil.minesAround > 0) return [index];
        return [
            index,
            ...getNearIndexes(index, state.rows, state.columns).reduce(
                (lastIndexes: number[], ceilIndex: number) => {
                    return [...lastIndexes, ...walkCeils(ceilIndex)];
                },
                []
            ),
        ];
    }
}

// Получение индексов соседних ячеек
function getNearIndexes(index: number, rows: number, columns: number): number[] {
    if (index < 0 || index >= rows * columns) return [];
    const row = Math.floor(index / columns);
    const column = index % columns;
    return [
        index - columns - 1,
        index - columns,
        index - columns + 1,
        index - 1,
        index + 1,
        index + columns - 1,
        index + columns,
        index + columns + 1,
    ].filter((_, arrayIndex) => {
        if (row === 0 && arrayIndex < 3) return false;
        if (row === rows - 1 && arrayIndex > 4) return false;
        if (column === 0 && [0, 3, 5].includes(arrayIndex)) return false;
        if (column === columns - 1 && [2, 4, 7].includes(arrayIndex)) return false;
        return true;
    });
}

// Хук для таймера
function useTimer(status: string): number {
    const [seconds, setSeconds] = useState<number>(0);

    function addSecond() {
        setSeconds((sec) => sec + 1);
    }

    useEffect(() => {
        let timer: number | undefined;
        switch (status) {
            case 'started':
                timer = window.setInterval(addSecond, 1000);
                break;
            case 'new':
                setSeconds(0);
                break;
            default:
                break;
        }
        return () => {
            if (timer !== undefined) clearInterval(timer);
        };
    }, [status]);
    return seconds;
}
