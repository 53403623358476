export async function fullScreen(element: HTMLElement) {
    if (element.requestFullscreen) {
        await element.requestFullscreen();
    }
}

export async function fullScreenCancel() {
    if (!document.fullscreenElement) {
        return;
    }

    await document.exitFullscreen();
}
