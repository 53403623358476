import { navigation } from 'common/utils/routeUtils.ts';
import { useTranslation } from 'i18n';

export const InviteContestBanner = () => {
    const { t } = useTranslation();
    return (
        <button
            className="w-full bg-gradient-to-b from-white via-[#D3DEFA] to-white bg-white border border-white shadow-[0px_20px_40px_0px_#88878740] flex justify-center items-center gap-1.5 p-1 h-[50px]"
            onClick={navigation.inviteContest}
        >
            <img src="/inviteContest/contest-2-star.png" alt="" className="w-7 h-7" />
            <span className="font-semibold leading-none xs:text-base text-xs">
                {t('invite-contest.invite-button')}
            </span>
        </button>
    );
};
