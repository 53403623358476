import { BaseEntityStore } from 'common/constants/types.ts';
import { Coin, fetchCoinBalanceById } from 'coin/coin.store.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { uniqBy } from 'lodash-es';
import { useStore } from '@nanostores/react';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { fetchTonBalance } from 'user/user.store.ts';

export type CoinTransaction = {
    id: string;
    coinId: Coin['id'];
    user: {
        id: string;
        username: string;
    } | null;
    actorAddress: string;
    tonFees: string;
    totalSupply: string;
    refId: number;
    openPrice: string;
    closePrice: string;
    lt: string;
    txUtime: string;
    txHash: string;
    createdAt: string;
    type: 'buy' | 'sell';
    coinsAmount: string;
    tonAmount: string;
    sendLiq: {
        tonLiq: string;
        jettonLiq: string;
    } | null;
};

interface CoinTransactions extends BaseEntityStore {
    transactions: CoinTransaction[];
    transactionsCursor?: string;
}

type CoinTransactionsStore = Record<Coin['id'], CoinTransactions>;

const defaultState = {
    isFetched: false,
    transactions: [],
    transactionsCursor: undefined,
};

export const $coinTransactions = map<CoinTransactionsStore>();

// actions

const getCoinTransactionsFromStore = (coinId: Coin['id']) => {
    return $coinTransactions.get()[coinId] ?? defaultState;
};

const updateCoinTransactions = action(
    $coinTransactions,
    'updateCoin',
    <K extends keyof CoinTransactions>(
        store: typeof $coinTransactions,
        coinId: Coin['id'],
        field: K,
        value: CoinTransactions[K]
    ) => {
        const currentState = getCoinTransactionsFromStore(coinId);
        store.setKey(coinId, {
            ...currentState,
            [field]: value,
        });
    }
);

export const fetchCoinTransactions = action(
    $coinTransactions,
    'fetchCoinTransactions',
    async (store, coinId: Coin['id']) => {
        updateCoinTransactions(coinId, 'isLoading', true);

        try {
            const { transactionsCursor, transactions } = getCoinTransactionsFromStore(coinId);

            const search = new URLSearchParams({
                limit: '10',
                ...(transactionsCursor
                    ? {
                          cursor: transactionsCursor,
                      }
                    : {}),
            });

            const response = await authorizedFetch(
                routes.coinByIdTransaction(coinId) + '?' + search.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    transactions: CoinTransaction[];
                    nextCursor: string;
                };

                updateCoinTransactions(
                    coinId,
                    'transactions',
                    uniqBy([...transactions, ...data.transactions], 'txHash')
                );
                updateCoinTransactions(coinId, 'transactionsCursor', data.nextCursor);
                updateCoinTransactions(coinId, 'isFetched', true);
            }
        } catch (e) {
            console.error('fetchCoinTransactions error', e);
        } finally {
            updateCoinTransactions(coinId, 'isLoading', false);
        }
    }
);

export const pullCoinTransactions = action(
    $coinTransactions,
    'pullCoinTransactions',
    async (store, coinId: Coin['id']) => {
        try {
            const { transactions } = getCoinTransactionsFromStore(coinId);

            const search = new URLSearchParams({
                limit: '9',
            });

            const response = await authorizedFetch(
                routes.coinByIdTransaction(coinId) + '?' + search.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    transactions: CoinTransaction[];
                    nextCursor: string;
                };

                data.transactions.forEach((transaction) => {
                    const isTransactionInStore = transactions.some(
                        (t) => t.txHash === transaction.txHash
                    );
                    if (
                        transaction.actorAddress === tonConnectUI.account?.address &&
                        !isTransactionInStore
                    ) {
                        fetchCoinBalanceById(coinId);
                        fetchTonBalance();
                    }
                });

                updateCoinTransactions(
                    coinId,
                    'transactions',
                    uniqBy([...data.transactions, ...transactions], 'txHash')
                );
            }
        } catch (e) {
            console.log('error on pullCoinTransactions', e);
        }
    }
);

// selectors

const selectTransactions = computed($coinTransactions, (store) => store);

// hooks

export const useCoinTransactions = (coinId: Coin['id']) => {
    const coinTransactions = useStore(selectTransactions)[coinId] ?? defaultState;

    return {
        ...coinTransactions,
        canFetchMore: Boolean(coinTransactions.transactionsCursor),
        isFetching: coinTransactions.isLoading && !coinTransactions.isFetched,
    };
};
