import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/react';
import {
    CREATE_COIN_APP,
    mapWindowKeyToContent,
    MINIMIZED_WINDOW_HEIGHT,
} from 'desktop/constants.tsx';
import { WindowBox, WindowBoxKeys, WindowBoxWithState, WindowState } from 'desktop/types.ts';
import { createPersistentState } from 'common/utils/createPersistentState.ts';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';
import { v4 as uuidv4 } from 'uuid';
import { CoinsGrid } from 'desktop/windows/coinsGrid.tsx';

type DesktopStore = {
    apps: WindowBoxWithState[];
};

const defaultValue: DesktopStore = {
    apps: [
        {
            minHeight: 50,
            minWidth: 400,
            maxHeight: 725,
            maxWidth: 1380,
            key: WindowBoxKeys.coinsList,
            title: 'Token List',
            icon: '/desktop/apps/token_list.gif',
            width: 838,
            height: 587.75,
            contentProps: { uuid: 'db6d33bb-8f4d-48fa-b172-ff75d3c6d5f6' },
            state: 'focused',
            x: 235,
            y: 45.8125,
            lastResize: { width: 838, height: 587.75 },
            lastPosition: { x: 235, y: 45.8125 },
            uuid: 'db6d33bb-8f4d-48fa-b172-ff75d3c6d5f6',
            content: CoinsGrid,
        },
    ],
};
const desktopPersistedStore = createPersistentState('desktop', defaultValue);

function loadFromLocalStorage(): DesktopStore {
    const parsed = desktopPersistedStore.value;

    return {
        ...parsed,
        apps: parsed.apps.map((windowBox) => ({
            ...windowBox,
            content: mapWindowKeyToContent[windowBox.key],
        })),
    };
}

export const $desktop = map<DesktopStore>(loadFromLocalStorage());

/** Обновляет одно конкретное окно по ключу, остальные не трогает */
function updateWindowBox(
    store: DesktopStore,
    uuid: string,
    updater: (win: WindowBoxWithState) => WindowBoxWithState
): DesktopStore {
    return {
        ...store,
        apps: store.apps.map((box) => (box.uuid === uuid ? updater(box) : box)),
    };
}

/** Находит окно по ключу и параметрам */
function findWindowBox(store: DesktopStore, app: WindowBox): WindowBoxWithState | undefined {
    return store.apps.find((box) => isEqualWindowBox(box, app));
}

/** Проверяет эквивалентность двух окон */
function isEqualWindowBox(a: WindowBox, b: WindowBox): boolean {
    return a.key === b.key && isEqual(omit(a.contentProps, 'uuid'), omit(b.contentProps, 'uuid'));
}

/** Найти окно по uuid */
function findWindowBoxByUuid(store: DesktopStore, uuid: string): WindowBoxWithState | undefined {
    return store.apps.find((box) => box.uuid === uuid);
}

export const openWindowBox = action($desktop, 'openWindowBox', (store, windowBox: WindowBox) => {
    const desktopStore = store.get();
    const existing = findWindowBox(desktopStore, windowBox);

    if (existing) {
        focusWindowBox(existing.uuid);
        return;
    }

    const uuid = uuidv4();
    const x = window.innerWidth / 4;
    const y = (window.innerHeight - windowBox.height) / 4;

    const newApp: WindowBoxWithState = {
        minHeight: 50,
        minWidth: 50,
        maxHeight: window.innerHeight - 40,
        maxWidth: window.innerWidth - 40,
        ...windowBox,
        contentProps: {
            ...windowBox.contentProps,
            uuid,
        },
        state: WindowState.Opened,
        x,
        y,
        content: mapWindowKeyToContent[windowBox.key],
        lastResize: { width: windowBox.width, height: windowBox.height },
        lastPosition: { x, y },
        uuid,
    };

    store.setKey('apps', [...desktopStore.apps, newApp]);

    focusWindowBox(newApp.uuid);
});

export const minimizeWindowBox = action($desktop, 'minimizeWindowBox', (store, uuid: string) => {
    const updated = updateWindowBox(store.get(), uuid, (box) => ({
        ...box,
        state: WindowState.Minimized,
    }));
    store.set(updated);
});

export const focusWindowBox = action($desktop, 'focusWindowBox', (store, uuid: string) => {
    const desktopStore = store.get();

    store.setKey(
        'apps',
        desktopStore.apps.map((box) => {
            if (box.uuid === uuid) {
                const isMinimized = box.state === WindowState.Minimized;
                return {
                    ...box,
                    state: WindowState.Focused,
                    width: isMinimized ? box.lastResize.width : box.width,
                    height: isMinimized ? box.lastResize.height : box.height,
                    x: box.lastPosition.x,
                    y: box.lastPosition.y,
                };
            }
            return {
                ...box,
                state: box.state === WindowState.Focused ? WindowState.Opened : box.state,
            };
        })
    );
});

export const toggleWindowBox = action($desktop, 'toggleWindowBox', (store, uuid: string) => {
    const desktopStore = store.get();
    const box = findWindowBoxByUuid(desktopStore, uuid);

    if (!box) return;

    if (box.state === WindowState.Focused) {
        minimizeWindowBox(uuid);
        return;
    }

    if (box.state === WindowState.Minimized) {
        resizeWindowBox(uuid, box.lastResize.width, box.lastResize.height);
    }
    focusWindowBox(uuid);
});

export const closeWindowBox = action($desktop, 'closeWindowBox', (store, uuid: string) => {
    const desktopStore = store.get();
    store.setKey(
        'apps',
        desktopStore.apps.filter((box) => box.uuid !== uuid)
    );
});

export const moveWindowBox = action(
    $desktop,
    'moveWindowBox',
    (store, uuid: string, x: number, y: number) => {
        if (x > window.innerWidth - 50 || y > window.innerHeight - 50) return;

        const updated = updateWindowBox(store.get(), uuid, (box) => ({
            ...box,
            x,
            y,
            lastPosition: { x, y },
        }));

        store.set(updated);
    }
);

export const resizeWindowBox = action(
    $desktop,
    'resizeWindowBox',
    (store, uuid: string, width: number, height: number) => {
        const desktopStore = store.get();
        const isMinimizing = height === MINIMIZED_WINDOW_HEIGHT;

        const updated = updateWindowBox(desktopStore, uuid, (box) => {
            return {
                ...box,
                width: isMinimizing
                    ? width
                    : Math.min(box.maxWidth ?? width, Math.max(width, box.minWidth ?? width)),
                height: isMinimizing
                    ? height
                    : Math.min(box.maxHeight ?? height, Math.max(height, box.minHeight ?? height)),
                lastResize: isMinimizing ? box.lastResize : { width, height },
            };
        });

        store.set(updated);
    }
);

const selectDesktopStore = computed($desktop, (store) => store);

export const useDesktopStore = () => useStore(selectDesktopStore);

$desktop.subscribe(desktopPersistedStore.save);

export const openCreateCoinApp = () => openWindowBox(CREATE_COIN_APP);
