import { Time } from 'lightweight-charts';
import { BaseEntityStore } from 'common/constants/types.ts';
import { Coin } from 'coin/coin.store.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { $ton } from 'main/main.store.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { uniqBy } from 'lodash-es';
import { useStore } from '@nanostores/react';

export type ChartTimeframe = 1 | 5 | 15;

type KLine = {
    startDttm: number;
    open: string | null;
    high: string | null;
    low: string | null;
    close: string | null;
    openMcap: string | null;
    highMcap: string | null;
    lowMcap: string | null;
    closeMcap: string | null;
};

type Candle = {
    time: Time;
    open: number;
    high: number;
    low: number;
    close: number;
};

interface CoinChart extends BaseEntityStore {
    serie: Candle[];
    range: [number, number] | null;
}

type CoinChartStore = Record<Coin['id'], CoinChart>;

const defaultState = {
    serie: [],
    range: null,
    isFetched: false,
};

export const $coinChart = map<CoinChartStore>();

// actions

const getCoinChartFromStore = (coinId: Coin['id']) => {
    return $coinChart.get()[coinId] ?? defaultState;
};

const updateCoinChart = action(
    $coinChart,
    'updateCoinChart',
    <K extends keyof CoinChart>(
        store: typeof $coinChart,
        coinId: Coin['id'],
        key: K,
        value: CoinChart[K]
    ) => {
        const currentState = getCoinChartFromStore(coinId);
        store.setKey(coinId, {
            ...currentState,
            [key]: value,
        });
    }
);

export const fetchKlines = action(
    $coinChart,
    'fetchKlines',
    async (
        store,
        params: { fromDttm: number; toDttm: number; coinId: string; stepMinute: ChartTimeframe }
    ) => {
        try {
            const prevSeries = getCoinChartFromStore(params.coinId).serie;

            const leftDttm = prevSeries.length ? (prevSeries[0].time as number) * 1000 : 0;
            const rightDttm = prevSeries.length
                ? (prevSeries[prevSeries.length - 1].time as number) * 1000
                : 0;
            const stepDelta = 3_600_000 * 24;

            const leftDttmWithDelta = leftDttm + stepDelta / 4;
            const rightDttmWithDelta = rightDttm - stepDelta / 4;

            if (
                prevSeries.length > 1 &&
                leftDttmWithDelta < params.fromDttm &&
                rightDttmWithDelta > params.toDttm
            ) {
                return;
            }

            if (!prevSeries.length) {
                params.fromDttm = params.toDttm - stepDelta;
            }

            if (prevSeries.length && params.fromDttm <= leftDttmWithDelta) {
                params.toDttm = leftDttm;
                params.fromDttm = leftDttm - stepDelta;
            }

            if (prevSeries.length && params.toDttm >= rightDttmWithDelta) {
                params.fromDttm = rightDttm;
                params.toDttm = rightDttm + stepDelta;
            }

            if (params.fromDttm > Date.now() - 59_000) {
                return;
            }

            if (params.fromDttm - params.toDttm > 60_000) {
                return;
            }

            if (params.toDttm > Date.now()) {
                params.toDttm = Date.now();
            }

            const searchParams = new URLSearchParams({
                fromDttm: String(params.fromDttm),
                toDttm: String(params.toDttm),
                coinId: String(params.coinId),
                stepMinute: String(params.stepMinute),
            });
            const response = await authorizedFetch(
                routes.coinChart + '?' + searchParams.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as { serie: KLine[] };

                const price = $ton.get().price;

                const serie = data.serie
                    .filter((candle) => Object.values(candle).every((val) => val))
                    .map((candle) => ({
                        open: parseFloat(parseValue(parseFloat(candle.openMcap ?? '0') * price, 9)),
                        close: parseFloat(
                            parseValue(parseFloat(candle.closeMcap ?? '0') * price, 9)
                        ),
                        low: parseFloat(parseValue(parseFloat(candle.lowMcap ?? '0') * price, 9)),
                        high: parseFloat(parseValue(parseFloat(candle.highMcap ?? '0') * price, 9)),
                        time: (candle.startDttm / 1000) as Time,
                    }));

                const newSerie = uniqBy([...prevSeries, ...serie], 'time');
                newSerie.sort((a, b) => (a.time as number) - (b.time as number));

                updateCoinChart(params.coinId, 'serie', newSerie);
                updateCoinChart(params.coinId, 'isFetched', true);
            }
        } catch (e) {
            console.log('fetchKlines error', e);
        }
    }
);

export const pullCoinKlines = action(
    $coinChart,
    'pullCoinKlines',
    async (store, params: { coinId: string; stepMinute: ChartTimeframe }) => {
        const isFetched = getCoinChartFromStore(params.coinId).isFetched;
        if (!isFetched) return;
        try {
            const searchParams = new URLSearchParams({
                fromDttm: String(Date.now() - 5_000),
                toDttm: String(Date.now()),
                coinId: String(params.coinId),
                stepMinute: String(params.stepMinute),
            });
            const response = await authorizedFetch(
                routes.coinChart + '?' + searchParams.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as { serie: KLine[] };

                const price = $ton.get().price;

                const prevSeries = getCoinChartFromStore(params.coinId).serie;

                const serie = data.serie
                    .filter((candle) => Object.values(candle).every((val) => val))
                    .map((candle) => ({
                        open: parseFloat(parseValue(parseFloat(candle.openMcap ?? '0') * price, 9)),
                        close: parseFloat(
                            parseValue(parseFloat(candle.closeMcap ?? '0') * price, 9)
                        ),
                        low: parseFloat(parseValue(parseFloat(candle.lowMcap ?? '0') * price, 9)),
                        high: parseFloat(parseValue(parseFloat(candle.highMcap ?? '0') * price, 9)),
                        time: (candle.startDttm / 1000) as Time,
                    }));

                const newSerie = uniqBy([...serie, ...prevSeries], 'time');
                newSerie.sort((a, b) => (a.time as number) - (b.time as number));

                updateCoinChart(params.coinId, 'serie', newSerie);
            }
        } catch (e) {
            console.log('fetchKlines error', e);
        }
    }
);

export const fetchKlinesRange = action(
    $coinChart,
    'fetchKlinesRange',
    async (store, coinId: Coin['id']) => {
        try {
            const searchParams = new URLSearchParams({
                coinId,
            });
            const response = await authorizedFetch(
                routes.coinChartRange + '?' + searchParams.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as { range: [number, number] };

                const range = data.range.map((x) => (x / 1000) as number) as [number, number];

                updateCoinChart(coinId, 'range', range);

                fetchKlines({
                    fromDttm: range[1] * 1000 - 3_600_000 * 24,
                    toDttm: range[1] * 1000,
                    coinId,
                    stepMinute: 1,
                });
            }
        } catch (e) {
            console.log('fetchKlinesRange error', e);
        }
    }
);

export const clearKlines = action($coinChart, 'clearKlines', (store, coinId: Coin['id']) => {
    updateCoinChart(coinId, 'serie', []);
    updateCoinChart(coinId, 'range', null);
    updateCoinChart(coinId, 'isFetched', false);
});

// selectors

const selectCoinChart = computed($coinChart, (store) => store);

// hooks

export const useCoinChart = (coinId: Coin['id']) =>
    useStore(selectCoinChart)[coinId] ?? defaultState;
