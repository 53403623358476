import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { uniqBy } from 'lodash-es';
import { useStore } from '@nanostores/react';
import { Coin } from 'coin/coin.store.ts';
import { fetchCoinsList } from 'main/api.ts';

export enum SortListType {
    Hot = 'hot',
    Featured = 'featured',
    TopMcap = 'top-mcap',
    New = 'new',
    PocketFi = 'pocketfi',
    MyTokens = 'my-tokens',
    Completing = 'completing',
}

interface CoinsStore extends BaseEntityStore {
    coins: Coin[];
    search: string;
    searchResults: Coin[];
    isSearchLoading: boolean;
    sortType: SortListType;
    cursor?: string;
}

export const $coins = map<CoinsStore>({
    coins: [],
    search: '',
    searchResults: [],
    isSearchLoading: false,
    sortType: SortListType.Hot,
    isFetched: false,
    isLoading: false,
});

// actions
export const getCoinsList = action($coins, 'fetchCoinsList', async (store, limit = '10') => {
    store.setKey('isLoading', true);

    try {
        const { sortType, cursor, coins } = store.get();

        const response = await fetchCoinsList({ sortType, cursor, limit });

        if (response.ok) {
            const data = (await response.json()) as { coins: Coin[]; nextCursor?: string };

            if (!store.get().cursor) {
                store.setKey('coins', data.coins);
            } else {
                store.setKey('coins', uniqBy([...coins, ...data.coins], 'id'));
            }

            store.setKey('cursor', data.nextCursor);

            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.log('fetchCoinsList error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const pullNewCoinsList = action($coins, 'pullCoinsList', async (store) => {
    try {
        const { coins, sortType } = store.get();

        if (sortType !== SortListType.New) {
            return;
        }

        const search = new URLSearchParams({
            limit: '9',
            sortType,
        });

        const response = await authorizedFetch(routes.coins + '?' + search.toString());

        if (response.ok) {
            const data = (await response.json()) as { coins: Coin[]; nextCursor?: string };

            if (store.get().sortType !== SortListType.New) {
                return;
            }

            store.setKey('coins', uniqBy([...data.coins, ...coins], 'id'));
        }
    } catch (e) {
        console.log('error on pullCoins', e);
    }
});

export const setCoinListSearch = action($coins, 'setCoinListSearch', (store, search: string) => {
    store.setKey('search', search);
    if (!search) {
        store.setKey('searchResults', []);
    }
});

export const searchCoins = action($coins, 'searchCoins', async (store) => {
    store.setKey('isSearchLoading', true);
    try {
        const { search } = store.get();

        if (search) {
            const response = await authorizedFetch(routes.coinsSearch + `?query=${search}`);

            if (response.ok) {
                const { coins } = (await response.json()) as { coins: Coin[] };

                store.setKey('searchResults', coins);
            }
        } else {
            store.setKey('searchResults', []);
        }
    } catch (e) {
        console.log('searchCoins error', e);
    } finally {
        store.setKey('isSearchLoading', false);
    }
});

export const setCoinListType = action(
    $coins,
    'setCoinListType',
    (store, sortType: SortListType, limit = '10') => {
        store.setKey('sortType', sortType);
        store.setKey('isFetched', false);
        store.setKey('cursor', undefined);
        store.setKey('coins', []);
        getCoinsList(limit);
    }
);

export const clearCurrentCursor = action($coins, 'clearCurrentCursor', (store) => {
    store.setKey('cursor', undefined);
});

// selectors

const selectCoins = computed($coins, (store) => ({
    ...store,
    canFetchMore: Boolean(store.cursor),
    isFetching: store.isLoading && !store.isFetched,
}));

// hooks

export const useCoinsList = () => useStore(selectCoins);
