import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchCoinById, useCoinById } from 'coin/coin.store.ts';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { getCoinMarketCap } from 'coin/utils.ts';
import { PriceChange } from 'coin/components/priceChange.tsx';
import { GoldShareCoinButton } from 'coin/components/goldShareCoinButton.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useTranslation } from 'i18n';
import { fetchTonPrice, useTon } from 'main/main.store.ts';
import { useEffect, useState } from 'react';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { Address } from '@ton/core';
import { CopyButton } from 'common/components/copyButton.tsx';
import { openExternalLink } from 'common/utils/openExternalLink.ts';
import { Socials } from 'coin/tabs/tokenAboutTab.tsx';
import { LiquidityProgress } from 'coin/tabs/liquidityProgress.tsx';
import { CoinStats } from 'coin/coinStats/coinStats.tsx';
import { Card } from 'common/components/card.tsx';
import { ChartWithControl } from 'coin/chart/chartWithControl.tsx';
import { RadioSlider } from 'common/components/radioSlider.tsx';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { BuyTokenWidget } from 'coin/components/buyTokenWidget.tsx';
import { SellTokenWidget } from 'coin/components/sellTokenWidget.tsx';
import { CommunityNotesHeader } from 'coin/communityNotes/communityNotes.tsx';
import { Holders } from 'coin/holders/holders.tsx';
import { TransactionHistory } from 'coin/transactions/transactionHistory.tsx';

type Props = {
    coinId: string;
};

const tokenTradeTabs = {
    buy: BuyTokenWidget,
    sell: SellTokenWidget,
};

const widgets = {
    notes: CommunityNotesHeader,
    holders: Holders,
    transactions: TransactionHistory,
};

export const CoinWindow = ({ coinId }: Props) => {
    const { t } = useTranslation();
    const { coin, coinBalance } = useCoinById(coinId);
    const { price } = useTon();

    useEffect(() => {
        if (!price) {
            fetchTonPrice();
        }
    }, [price]);

    useAutoFetch(() => fetchCoinById(coinId), 5000);

    const [tradeType, setTradeType] = useState<'buy' | 'sell'>('buy');
    const [displayedWidget, setDisplayedWidget] = useState<'holders' | 'notes' | 'transactions'>(
        'transactions'
    );

    if (!coin) {
        return <Skeleton size="lg" className="w-full h-full" />;
    }

    const TradeWidget = tokenTradeTabs[tradeType];
    const ActiveWidget = widgets[displayedWidget];

    return (
        <div className="bg-[#212121] w-full min-h-full flex flex-col items-stretch gap-2.5">
            <div className="grid grid-cols-[calc(100%-358px)_350px] grid-rows-[1fr_auto] gap-2 w-full">
                <div className="h-full flex items-stretch">
                    <ChartWithControl coin={coin} />
                </div>
                <div className="flex flex-col gap-2 relative">
                    <Card className="flex flex-col gap-3 !p-4 bg-gradient-to-b from-white/40 to-transparent !bg-blue">
                        <RadioSlider
                            value={tradeType}
                            onChange={setTradeType}
                            options={[
                                {
                                    value: 'buy',
                                    label: (
                                        <div className="flex items-center gap-1.5">
                                            <img
                                                src="/green_ball.png"
                                                alt=""
                                                className="w-4 h-4 -mt-0.5"
                                            />
                                            <span>{t('token.buy')}</span>
                                        </div>
                                    ),
                                },
                                {
                                    value: 'sell',
                                    label: (
                                        <div className="flex items-center gap-1.5">
                                            <img
                                                src="/red_ball.png"
                                                alt=""
                                                className="w-4 h-4 -mt-0.5"
                                            />
                                            <span>{t('token.sell')}</span>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <TradeWidget
                            coin={coin}
                            coinBalance={coinBalance ?? ('100' as NumericString)}
                            floatingButton={false}
                        />
                    </Card>
                    <div className="bg-[#002366] flex flex-col gap-[1px] border-2 border-black pb-2">
                        <div className="bg-blue flex flex-col gap-1 items-start p-2.5">
                            <div className="flex items-center gap-2 w-full">
                                <img
                                    src={coin.imageUrl}
                                    alt={coin.imageName}
                                    className="w-20 aspect-square object-contain"
                                />
                                <div className="flex flex-col gap-2.5">
                                    <div className="flex items-center gap-1">
                                        <span className="text-white">{coin.name}</span>
                                        <span className="text-sm text-yellow font-normal">
                                            {coin.symbol}
                                        </span>
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <span className="text-xs text-white/80 font-normal">
                                            {t('market-cap')}
                                        </span>
                                        <div className="flex items-center gap-2">
                                            <TokenMarketCap
                                                onlyNumber
                                                value={getCoinMarketCap(coin, price)}
                                            />
                                            <PriceChange
                                                value={parseFloat(coin.priceChange1H)}
                                                className="text-sm"
                                                colors={{
                                                    positive: 'text-oxid-green',
                                                    negative: 'text-red',
                                                }}
                                                arrow
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex ml-auto">
                                    <GoldShareCoinButton coinId={coinId} />
                                </div>
                            </div>
                            <p className="text-xs text-white">{coin.description}</p>
                            <div
                                className="grid font-normal text-white/50 items-center gap-2.5"
                                style={{
                                    gridTemplateColumns: 'auto 1fr',
                                }}
                            >
                                <span className="text-xs ">{t('token.address')}</span>
                                <div className="flex items-center gap-2">
                                    <span className="text-oxid-green-v2 text-sm font-normal">
                                        {squeezeAddress(
                                            Address.parse(coin.address).toString({
                                                bounceable: true,
                                            })
                                        )}
                                    </span>
                                    <div className="flex items-center gap-1">
                                        <CopyButton
                                            text={Address.parse(coin.address).toString({
                                                bounceable: true,
                                            })}
                                        >
                                            <img
                                                src="/copyAddress.svg"
                                                alt="copy"
                                                className="cursor-pointer h-4"
                                            />
                                        </CopyButton>
                                        <span className="text-lg font-normal">/</span>
                                        <button
                                            onClick={() =>
                                                openExternalLink(
                                                    `https://tonviewer.com/${Address.parse(coin.address).toString({ bounceable: true })}`
                                                )
                                            }
                                        >
                                            <img
                                                src="/tonviewerLink.svg"
                                                alt="tonviewer"
                                                className="h-[1.125rem] cursor-pointer"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <span className="text-xs ">{t('token.authorAddress')}</span>
                                <div className="flex items-center gap-2">
                                    <span className="text-oxid-green-v2 text-sm font-normal">
                                        {squeezeAddress(
                                            Address.parse(coin.authorAddress).toString({
                                                bounceable: true,
                                            })
                                        )}
                                    </span>
                                    <div className="flex items-center gap-1">
                                        <CopyButton
                                            text={Address.parse(coin.authorAddress).toString({
                                                bounceable: true,
                                            })}
                                        >
                                            <img
                                                src="/copyAddress.svg"
                                                alt="copy"
                                                className="cursor-pointer h-4"
                                            />
                                        </CopyButton>
                                        <span className="text-lg font-normal">/</span>
                                        <button
                                            onClick={() =>
                                                openExternalLink(
                                                    `https://tonviewer.com/${Address.parse(coin.authorAddress).toString({ bounceable: true })}`
                                                )
                                            }
                                        >
                                            <img
                                                src="/tonviewerLink.svg"
                                                alt="tonviewer"
                                                className="h-[1.125rem] cursor-pointer"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Socials coin={coin} />
                    <LiquidityProgress coin={coin} />
                </div>
                <Card className="!p-0 !bg-transparent !border-transparent border-4">
                    <div className="flex flex-col gap-2.5 relative" id="community-notes">
                        <div className="p-3 pb-0 bg-gradient-to-b from-[#1253D1CC] to-transparent">
                            <RadioSlider
                                className="border-none"
                                value={displayedWidget}
                                onChange={setDisplayedWidget}
                                options={[
                                    {
                                        value: 'transactions',
                                        label: t('token.transactions'),
                                    },
                                    {
                                        value: 'holders',
                                        label: (
                                            <div className="flex items-center gap-1.5">
                                                <span>{t('holders')}</span>
                                                <span className="text-blue-sky font-normal text-xs">
                                                    {coin.holderAmount}
                                                </span>
                                            </div>
                                        ),
                                    },
                                    {
                                        value: 'notes',
                                        label: t('community-notes'),
                                    },
                                ]}
                            />
                        </div>
                        <ActiveWidget tokenId={coinId} count={parseFloat(coin.noteAmount)} />
                    </div>
                </Card>
                <div className="flex flex-col gap-2 relative">
                    <Card className="!bg-blue">
                        <CoinStats coinId={coin.id} />
                    </Card>
                </div>
            </div>
        </div>
    );
};
