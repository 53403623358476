import classNames from 'classnames';
import { setCoinListType, SortListType, useCoinsList } from 'main/coinsList.store.ts';
import { useMemo } from 'react';
import { TranslateFn, useTranslation } from 'i18n';

export type Tab<T> = {
    label: string;
    value: T;
    alwaysHighlighted?: boolean;
    icon?: string;
    iconClassName?: string;
};

type TabsProps = {
    fitContent?: boolean;
    tabClassName?: string;
    containerClassName?: string;
    limit?: number;
};

const mapTabNameWithType = (t: TranslateFn) => {
    return {
        [SortListType.Hot]: '',
        [SortListType.Featured]: '',
        [SortListType.Completing]: '',
        [SortListType.TopMcap]: t('main.tabs.topMcap'),
        [SortListType.New]: t('main.tabs.new'),
        [SortListType.PocketFi]: t('main.tabs.pocketFi'),
        [SortListType.MyTokens]: t('main.tabs.myTokens'),
    };
};

const SORT_LIST_TABS = [
    SortListType.Hot,
    SortListType.Featured,
    SortListType.Completing,
    SortListType.TopMcap,
    SortListType.New,
    SortListType.PocketFi,
    SortListType.MyTokens,
];

export const Tabs = ({ fitContent, tabClassName, containerClassName, limit }: TabsProps) => {
    const { t } = useTranslation();
    const { sortType: activeTab } = useCoinsList();

    const tabs: Tab<SortListType>[] = useMemo(() => {
        const getIcon = (tab: SortListType) => {
            switch (tab) {
                case SortListType.Hot:
                    return '/fire-flame.gif';
                case SortListType.Featured:
                    return '/slon.gif';
                case SortListType.Completing:
                    return '/mountain.png';
                default:
                    return undefined;
            }
        };

        const getIconClassName = (tab: SortListType) => {
            switch (tab) {
                case SortListType.Completing:
                    return '-mt-1';
                default:
                    return '';
            }
        };

        return SORT_LIST_TABS.map((tab) => ({
            label: mapTabNameWithType(t)[tab],
            value: tab,
            alwaysHighlighted: tab === SortListType.Hot,
            icon: getIcon(tab),
            iconClassName: getIconClassName(tab),
        }));
    }, [t]);

    return (
        <div
            className={classNames(
                'flex items-center justify-between gap-1 overflow-x-auto no-scrollbar py-5',
                containerClassName,
                {
                    'w-full': !fitContent,
                }
            )}
        >
            {tabs.map((tab) => (
                <button
                    key={tab.value}
                    className={classNames(
                        'border border-black bg-gradient-to-b text-xs px-1 py-[5px] whitespace-nowrap text-center flex justify-center items-center leading-4 h-7 rounded-[10px]',
                        {
                            'from-[#FFB000] to-[#FFD16B] shadow-[0px_-3px_0px_0px_#305294]':
                                activeTab === tab.value,
                            'from-[#4A6FB7] to-[#305294] text-white shadow-[0px_3px_0px_0px_#305294]':
                                activeTab !== tab.value,
                            '!border-[#FFD16B]': activeTab !== tab.value && tab.alwaysHighlighted,
                            'flex-1': !fitContent,
                        },
                        tabClassName
                    )}
                    onClick={() => setCoinListType(tab.value, limit)}
                >
                    {tab.icon && (
                        <img
                            src={tab.icon}
                            alt=""
                            className={classNames('w-5 h-5 min-w-[20px]', tab.iconClassName)}
                        />
                    )}
                    {tab.label}
                </button>
            ))}
        </div>
    );
};
