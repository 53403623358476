import { useTon } from 'main/main.store.ts';
import { Card } from 'common/components/card.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { Coin } from 'coin/coin.store.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { getCoinMarketCap } from 'coin/utils.ts';

type MyCoinCardProps = { coin: Coin };

export const MyCoinCard = ({ coin }: MyCoinCardProps) => {
    const { price } = useTon();
    const jettonValueInUsd = parseFloat(parseValue(coin?.jettonInTonAmount ?? 0, 9)) * price;

    const amountUSD = Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
        maximumFractionDigits: 2,
    }).format(jettonValueInUsd);

    const amountJetton = Intl.NumberFormat('en', {
        notation: 'compact',
        maximumFractionDigits: 2,
    }).format(parseFloat(parseValue(coin?.userJettonAmount ?? 0, 9)));

    return (
        <Card className="flex flex-col gap-2 !p-3" onClick={navigation.tokenTrade(coin.id)}>
            <div className="flex items-center gap-2 w-full">
                <div className="relative">
                    <img
                        src={coin.imageUrl}
                        alt={coin.imageName}
                        className="min-w-[72px] w-[72px] max-h-[72px] min-h-[72px] object-cover object-center"
                    />
                </div>
                <div className="flex items-center h-full justify-between gap-1 w-[calc(100%-80px)]">
                    <div className="flex flex-col items-start gap-1 flex-1">
                        <span className="text-sm font-semibold line-clamp-1">{coin.name}</span>
                        <span className="text-xs opacity-60">
                            {amountJetton} {coin.symbol}
                        </span>
                        <TokenMarketCap isNew value={getCoinMarketCap(coin, price)} />
                    </div>
                    <div className="flex flex-col items-end gap-1">
                        <span className="font-bold">{amountUSD}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};
