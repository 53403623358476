import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useEffect, useState } from 'react';

type Props = {
    onClose: () => void;
};

export const ShutDownPage = ({ onClose }: Props) => {
    const [isBlack, setIsBlack] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsBlack(true);
        }, 5000);
    }, []);

    if (isBlack) {
        return (
            <div className="fixed top-0 left-0 w-screen h-screen bg-black z-50" onClick={onClose} />
        );
    }

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#627DD5] grid grid-rows-5 z-50">
            <div className="row-span-1 bg-[#102F96] relative loginScreen__bg_top" />
            <div className="row-span-3 grid grid-cols-2 relative loginScreen__bg_center">
                <div />
                <div className="pl-10 flex flex-col justify-center gap-10">
                    <div className="flex flex-col items-start justify-center gap-0">
                        <div className="flex items-end justify-between gap-2">
                            <div className=" text-white flex items-center gap-1.5 text-md w-fit font-semibold">
                                <span>by</span>
                                <img
                                    src="/pocketfi_logo.svg"
                                    alt="pocketfi"
                                    className="w-5 h-5 min-w-5"
                                />
                                <span>PocketFi</span>
                            </div>
                            <img
                                src="/desktop/pump_logo.png"
                                alt="pump"
                                className="min-w-[1.75rem] w-32 h-32"
                            />
                        </div>
                        <TextWithShadow shadowWidth={2} className="italic text-[60px]">
                            BIG.PUMP
                        </TextWithShadow>
                    </div>
                    <p className="text-xl text-white font-semibold">Shutting down...</p>
                </div>
            </div>
            <div className="row-span-1 bg-[#102F96] relative loginScreen__bg_bottom" />
        </div>
    );
};
