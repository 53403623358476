import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { InviteFrens } from 'frens/tabs/inviteFrens.tsx';

export const FrensPage = () => {
    useBackButton({
        from: '/frens',
        callback: navigation.mainPage,
    });

    return (
        <div className="flex flex-col items-center w-full gap-2">
            <InviteFrens />
        </div>
    );
};

export const FrensBonus = ({
    percent,
    hot,
    size = 'default',
}: {
    percent: number;
    hot?: boolean;
    size?: 'default' | 'big';
}) => {
    const sizeClass = size === 'big' ? 'w-16' : 'w-12';
    const textSize = size === 'big' ? 'text-xl' : 'text-normal';
    const gifSize = size === 'big' ? 'h-18' : 'h-14';
    return (
        <div
            className={`aspect-square ${sizeClass} min-w-[3rem] bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] flex items-center justify-center relative`}
        >
            {hot && (
                <img
                    src="/hot_label.png"
                    alt=""
                    className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
            )}
            <TextWithShadow
                className={`!text-yellow italic z-10 ${textSize}`}
            >{`${percent}%`}</TextWithShadow>
            <img
                src="/frens_rate_chess.gif"
                alt=""
                className={`absolute ${gifSize} -bottom-1 left-0`}
            />
        </div>
    );
};
