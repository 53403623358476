import { PropsWithChildren } from 'react';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchCoinById } from 'coin/coin.store.ts';
import { useParams } from 'react-router';

export const TokenPage = ({ children }: PropsWithChildren) => {
    const { tokenId } = useParams();

    if (!tokenId) {
        throw new Error('Should be used on token page');
    }

    useAutoFetch(() => fetchCoinById(tokenId), 5000);

    return <div className="flex flex-col gap-2.5 relative">{children}</div>;
};
