import { useRef } from 'react';
import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const keyRef = useRef(key);
    const valueRef = useRef(initialValue);

    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? (JSON.parse(item) as T) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(valueRef.current) : value;

                setStoredValue(valueToStore);
                valueRef.current = valueToStore;

                if (typeof window !== 'undefined') {
                    window.localStorage.setItem(keyRef.current, JSON.stringify(valueToStore));
                }
            } catch (error) {
                console.log(error);
            }
        },
        [valueRef, setStoredValue]
    );

    return [storedValue, setValue] as const;
};
