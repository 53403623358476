import 'winbox/dist/css/winbox.min.css'; // required
import 'winbox/dist/css/themes/modern.min.css'; // optional
import 'winbox/dist/css/themes/white.min.css';
import './styles.css';
import { WindowBoxWithState } from 'desktop/types.ts';
import { openWindowBox, useDesktopStore } from 'desktop/desktop.store.ts';
import { WrapperWinBox } from 'desktop/wrapperWinBox.tsx';
import { APPS } from 'desktop/constants.tsx';
import { CommandBar } from 'desktop/components/commandBar.tsx';
import { WalletConnectModal } from 'tonConnect/walletConnectModal.tsx';

export const DesktopPage = () => {
    const { apps } = useDesktopStore();

    return (
        <div className="flex flex-col items-center justify-start h-full bg-[url('/desktop/desktop_bg.jpeg')] bg-cover">
            <CommandBar />
            <div className="relative grid grid-cols-[repeat(auto-fill,80px)] grid-rows-[repeat(auto-fill,100px)] grid-flow-col h-full p-5 gap-5 w-full">
                {APPS.map((app) => (
                    <button
                        key={app.key}
                        className="p-2 w-full h-full text-white text-xs flex flex-col items-center"
                        onClick={() => {
                            openWindowBox(app);
                        }}
                    >
                        <img src={app.icon} alt="" className="w-full aspect-square" />
                        <span>{app.title}</span>
                    </button>
                ))}
                <button
                    key="poh"
                    className="p-2 w-full h-full text-white text-xs flex flex-col items-center"
                    onClick={() => {
                        window.open('https://t.me/pocketfi_bot/Verify', '_blank');
                    }}
                >
                    <img src="/human.png" alt="" className="w-full aspect-square" />
                    <span>Proof of Humanity</span>
                </button>
                {apps.map((window: WindowBoxWithState) => (
                    <WrapperWinBox key={window.uuid} window={window} />
                ))}
            </div>
            <WalletConnectModal />
        </div>
    );
};
