interface ParsedStartParams {
    referralCode?: string;
    params?: Record<string, string>;
}

export function parseStartParam(startParam?: string): ParsedStartParams {
    if (startParam == null) {
        return {};
    }
    try {
        const result: ParsedStartParams = {};

        const [referralCode, paramsString] = startParam.replace(/^\?startapp=/, '').split('-', 2);

        if (referralCode) {
            result.referralCode = referralCode;
        }

        if (paramsString) {
            result.params = JSON.parse(atob(decodeURIComponent(paramsString)));
        }

        return result;
    } catch (e) {
        return {};
    }
}
