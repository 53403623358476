import { useEffect, useRef } from 'react';

export const useScrollToFetchData = (fetchMoreData: () => void, isLoading?: boolean) => {
    const bottom = useRef(null);
    const isLoadingRef = useRef(isLoading);

    useEffect(() => {
        isLoadingRef.current = isLoading;
    }, [isLoading]);

    useEffect(() => {
        const bottomCurrent = bottom.current;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting || isLoadingRef.current) {
                    return;
                }
                fetchMoreData();
            });
        }, options);

        if (bottomCurrent) {
            observer.observe(bottomCurrent);
        }

        return () => {
            if (bottomCurrent) {
                observer.unobserve(bottomCurrent);
            }
        };
    }, [fetchMoreData, bottom.current]);

    return bottom;
};
